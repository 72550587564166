import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { useDispatch } from 'react-redux';

import FilterMainCategory from '../../Components/Filter/MainCategory';
import FilterCategory from '../../Components/Filter/Category';
import FilterTag from '../../Components/Filter/Tag';
import ShopAd from '../../Components/ShopAd';
import constants from '../../Config/constants';
import { setLoadAllStoresAndActiveOrders } from '../../RTK/utility';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import HorizontalListingService from './HorizontalListingService';

const HomepageListHeader = ({
  // for FilterTag
  isLoading,
  resultCount,
  // for pull to refresh
  refreshing,
  // for ShopAd & Horizontal Listing
  showAdAndHorizontalList,
  segments,
}) => {
  const dispatch = useDispatch();
  const [fetched, setFetched] = useState(0); // number of sections sucessfully fetched

  // hooks for listening if should load the all stores and active orders once all section is done fetching
  useEffect(() => {
    if (fetched === segments.length) {
      // if fetched count and sections/segments count match it means all sections has been loaded
      dispatch(setLoadAllStoresAndActiveOrders(true)); // load all stores and active orders
    }
  }, [fetched]);

  // hooks for resetting the fetched count and should load all stores and active orders
  // used only on mobile, refreshing is not getting true on web because it will only get true if user pull to refresh
  useEffect(() => {
    if (refreshing) {
      if (showAdAndHorizontalList) {
        // if showAdAndHorizontalList is true, it means no filter apply
        dispatch(setLoadAllStoresAndActiveOrders(false)); // do not load all stores and active orders yet
      }
      setFetched(0); // reset the fetched count to 0
    }
  }, [refreshing]);

  // hooks for flagging the initial load or not AND loading all stores and active orders to true if reload while it has filter selected
  useEffect(() => {
    if (!showAdAndHorizontalList) {
      // on web, filter can still retain after refreshing the page so,
      // if onload and showAdAndHorizontalList = false it means it has filter enabled on load
      dispatch(setLoadAllStoresAndActiveOrders(true)); // load all stores and active orders
    }
  }, []);

  const _setFetched = () => {
    setFetched((e) => {
      const next = e + 1;
      if (next <= segments.length) {
        // continue incrementing
        return next;
      }
      // stopped incrementing
      return e;
    });
  };

  return (
    <Fragment>
      {!constants.isWeb && (
        // absolute positioned search bar height on top of it
        <View style={{ marginTop: 48 }}>
          {constants.USE_PARENT_CATEGORY && <FilterMainCategory />}
          <FilterCategory />
          <FilterTag isLoading={isLoading} resultCount={resultCount} />
        </View>
      )}

      {showAdAndHorizontalList && (
        <Fragment>
          {/* <ShopAd /> */}

          {segments.map((list, index) => (
            <HorizontalListingService
              key={index}
              refreshing={refreshing}
              enabled={fetched >= index}
              onDone={_setFetched}
              {...list}
            />
          ))}

          <View
            style={[
              ThemeStyle.spacingTopMedium,
              !constants.isWeb && ThemeStyle.pageHorizontalSpacing,
            ]}
          >
            <Text
              style={ThemeStyle.flex1}
              {...(constants.isWeb ? { category: 's1' } : SECTION_TITLE)}
            >
              All Stores
            </Text>
          </View>
        </Fragment>
      )}
    </Fragment>
  );
};

HomepageListHeader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  resultCount: PropTypes.number.isRequired,
  refreshing: PropTypes.bool,
  showAdAndHorizontalList: PropTypes.bool.isRequired,
  segments: PropTypes.array.isRequired,
  onSearchPress: PropTypes.func,
};

export default HomepageListHeader;
