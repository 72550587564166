import { useDispatch, useSelector } from 'react-redux';

import constants from '../../Config/constants';
import messages from '../../Config/messages';
import useToast from '../../Hooks/useToast';
import { setRescheduleStoreId } from '../../RTK/utility';
import { shopSelector } from '../../RTK/shop/selectors';
import { updateCheckoutDetails } from '../../RTK/checkout';
import { checkout } from '../../RTK/defaultValues';
import dayjs from 'dayjs';
import { changeWhenFilter } from '../../RTK/filter';

const useSchedulePicker = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const storeId = useSelector((state) => shopSelector(state)?.id);
  const rescheduleStoreId = useSelector(
    (state: any) => state.utility.rescheduleStoreId // Fix typings of utility
  );

  const _rescheduleOnStorePage = (date: string) => {
    const dayjsObject = dayjs(date);
    const value = {
      label: dayjsObject.format(constants.DATE_DISPLAY_FORMAT),
      value: dayjsObject.toISOString(),
      date: dayjsObject.format(constants.DBDATE_FORMAT),
      time: dayjsObject.format(constants.DBTIME_FORMAT),
    };
    // set checkout selected schedule
    dispatch(
      updateCheckoutDetails({
        store_id: storeId,
        keyToUpdate: checkout.keys.DELIVERY_SCHEDULE,
        keyValue: value,
      })
    );
    toast.show(`${messages.CHANGE_SCHEDULE} ${value?.label || 'ASAP'}.`); // show toast to user
    dispatch(changeWhenFilter(value)); // update date filter on homepage
    _setRescheduleStoreId('');
  };

  const _setRescheduleStoreId = (storeId: string) => {
    dispatch(setRescheduleStoreId(storeId));
  };

  return {
    showReschedule: Boolean(rescheduleStoreId),
    rescheduleStoreId,
    rescheduleOnStorePage: _rescheduleOnStorePage,
    setRescheduleStoreId: _setRescheduleStoreId,
  };
};

export default useSchedulePicker;
