import React, { Fragment, useState } from 'react';
import { View, SafeAreaView, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import StyleSheet from 'react-native-media-query';
import { useSelector } from 'react-redux';
import lodashIsNull from 'lodash/isNull';
import lodashIsUndefined from 'lodash/isUndefined';
import lodashGet from 'lodash/get';
import dayjs from 'dayjs';

import { Icon, Text, useTheme } from '@ui-kitten/components';

import Button from '../Button';
import Image from '../Image';
import DatePicker from '../DatePicker';

import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

import withDatePicker from '../../Hooks/withDatePicker';

import PickupWordLogo from '../../Assets/PickupWordLogo.svg';

import { styles as homeStyles } from '../../Screens/Home/styles';
import { shopSelector } from '../../RTK/shop/selectors';
import { selectCart } from '../../RTK/cart/selectors';
import constants from '../../Config/constants';

const Header = ({
  hasBackButton = true,
  backButtonText = 'Back to Store',
  plain = false,
  onPressBack,
  onConfirm,
  whenFilter,
  route = {},
}) => {
  const store_theme = useTheme();
  const navigation = useNavigation();
  const { cart } = useSelector((state) => state);
  const shopData = useSelector(shopSelector);
  const actualCart = useSelector((state) => selectCart(state, shopData?.id));

  //
  // Start Region - Handling transition from params date to date picker
  //
  const order_date = lodashGet(
    whenFilter,
    'date',
    lodashGet(route, 'params.order_date'),
    null
  );
  const order_time = lodashGet(
    whenFilter,
    'time',
    lodashGet(route, 'params.order_time'),
    null
  );

  let actual_order_date = null;
  // Check if user already used date picker
  const datePickerTouched = localStorage.getItem('datePickerTouched');

  if (!datePickerTouched) {
    if (
      !lodashIsNull(order_date) &&
      !lodashIsNull(order_time) &&
      order_time !== 'asap_order_time'
    ) {
      actual_order_date = new Date(`${order_date} ${order_time}`);
    }
  } else {
    actual_order_date = lodashIsUndefined(whenFilter)
      ? null
      : whenFilter?.value;
  }
  //
  // End Region - Handling transition from params date to date picker
  //

  const [dateVisible, setDateVisible] = useState(false);

  const itemCount = actualCart
    .map((item) => item.items)
    .map((arr) => arr.map((obj) => obj.quantity))
    .flat(cart.cartData.length)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  const toggleRightDrawer = () =>
    navigation.getParent('RightDrawer').toggleDrawer();

  if (plain) {
    return (
      <View style={[styles.wrapper, { backgroundColor: '#fff' }]}>
        <View style={styles.leftColumnHeader}>
          {hasBackButton && (
            <Button onPress={onPressBack} style={styles.leftColumnAction} plain>
              <Icon
                name={'ios-chevron-back'}
                fill={ThemeColor.green}
                pack="ion"
                style={styles.actionIconSize}
              />
              <Text>{backButtonText}</Text>
            </Button>
          )}
        </View>
        <View style={styles.centerColumn}>
          <PickupWordLogo width={130} height={50} />
        </View>
        <View style={styles.rightColumnHeader} />
      </View>
    );
  }

  return (
    <Fragment>
      <SafeAreaView style={styles.wrapper}>
        <View
          dataSet={{ media: ids.leftColumnHeader }}
          style={styles.leftColumnHeader}
        >
          <Button
            onPress={() =>
              shopData.brand_url
                ? window.location.assign(shopData.brand_url)
                : window.history.back()
            }
            style={[
              styles.leftColumnAction,
              { marginRight: globalSpacing / 4 },
            ]}
            plain
          >
            <Icon
              name={'ios-chevron-back'}
              fill={store_theme['button_color'] || ThemeColor.green}
              pack="ion"
              style={styles.actionIconSize}
            />
          </Button>
          <Button onPress={() => setDateVisible(true)} plain>
            <View style={[ThemeStyle.flexDirectionRowCenterCenter]}>
              <Icon
                name="calendar-outline"
                fill={store_theme['button_color'] || ThemeColor.black}
                pack="eva"
                style={[styles.leftIcon, { width: 20, height: 20 }]}
              />
              <View
                dataSet={{ media: ids.dateContainer }}
                style={[styles.dateContainer]}
              >
                <Text category="p1" numberOfLines={1}>
                  {lodashIsNull(actual_order_date)
                    ? 'ASAP'
                    : dayjs(actual_order_date).format(
                        constants.DATE_TIME_FILTER_DISPLAY
                      )}
                </Text>
              </View>

              <Icon
                name="chevron-down-outline"
                fill={store_theme['button_color'] || ThemeColor.black}
                pack="eva"
                style={[styles.rightIcon, { width: 20, height: 20 }]}
              />
            </View>
          </Button>
        </View>

        <View style={styles.centerColumn}>
          <Image
            source={{ uri: shopData?.brand?.brand_logo }}
            resizeMode="contain"
            style={styles.storeLogo}
          />
        </View>

        <View
          dataSet={{ media: ids.rightColumnHeader }}
          style={styles.rightColumnHeader}
        >
          <View
            dataSet={{ media: ids.rightColumnActionContainer }}
            style={styles.rightColumnActionContainer}
          >
            <Button
              testID="cartButton"
              onPress={toggleRightDrawer}
              style={{
                ...styles.rightColumnAction,
                backgroundColor:
                  store_theme['button_color'] || ThemeColor.green,
              }}
              plain
            >
              <Icon
                name={'basket-outline'}
                fill={store_theme['button_text_color'] || ThemeColor.white}
                pack="ion"
                style={styles.actionIconSize}
              />

              {itemCount > 0 && (
                <View style={styles.cartTxtContainer}>
                  <Text
                    style={{
                      ...styles.itemCountTxt,
                      color:
                        store_theme['button_text_color'] || ThemeColor.white,
                    }}
                  >
                    {itemCount}
                  </Text>
                </View>
              )}
            </Button>
          </View>
        </View>
      </SafeAreaView>

      <DatePicker
        isOpen={dateVisible}
        onClosed={() => setDateVisible(false)}
        onProceed={(date) => {
          onConfirm(date);
          setDateVisible(false);
          localStorage.setItem('datePickerTouched', true);
        }}
        date={`${order_date} ${order_time}`}
      />
    </Fragment>
  );
};
const { ids, styles } = StyleSheet.create({
  wrapper: {
    borderBottomColor: ThemeColor.gray,
    borderBottomWidth: 1,
    height: 65,
    display: 'flex',
    paddingLeft: '5%',
    paddingRight: '5%',
    flexDirection: 'row',
  },
  leftColumnHeader: {
    ...ThemeStyle.flex1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  centerColumn: {
    ...ThemeStyle.flex1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightColumnHeader: {
    ...ThemeStyle.flex1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logoContainer: {
    display: 'flex',
  },
  actionIconSize: { width: 25, height: 25 },
  rightColumnActionContainer: {
    display: 'flex',
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
  },
  rightColumnAction: {
    width: 75,
    height: 40,
    borderRadius: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cartTxtContainer: { width: 25, alignItems: 'center' },
  leftColumnAction: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemCountTxt: { fontSize: 18 },
  ...homeStyles,
  storeLogo: {
    width: 130,
    height: 50,
  },
  leftIcon: {
    marginRight: globalSpacing / 4,
  },
  rightIcon: {
    marginLeft: globalSpacing / 4,
  },
  dateContainer: {
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
  },
});

export default withDatePicker(React.memo(Header));
