import React, { Fragment, memo } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { Icon, Layout, Text } from '@ui-kitten/components';
import * as Progress from 'react-native-progress';

import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsUndefined from 'lodash/isUndefined';

import Button from '../../Components/Button';

import { RectangleLoader } from '../../Components/Loader';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

import useController from './controller/rating';

import RatingStar from '../../Components/RatingStar';

import { numberToArray } from '../../Helper';

import constants from '../../Config/constants';

const Rating = ({ storeId, reviewData }) => {
  const { average, total, rating_summary, status } = reviewData;
  const { toggleShowNote } = useController(storeId);

  const filter = rating_summary?.sort(function (a, b) {
    return parseFloat(b.rating) - parseFloat(a.rating);
  });

  const FilterRatingStar = () => {
    if (lodashIsEmpty(filter)) {
      return null;
    }

    return filter.map((item, index) => {
      const rawPercentage = (item.total / total) * 100;
      const totalRatePercentage = rawPercentage.toFixed(2) / 100;
      return (
        <View key={index} style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
          <View
            style={[
              ThemeStyle.flexDirectionRowCenterSpaceBetween,
              {
                width: constants.isWeb ? 30 : 25,
              },
            ]}
          >
            <Text style={styles.filterLabel} category="p2">
              {item.rating}
            </Text>

            <Icon
              name={'star'}
              style={styles.iconSize}
              pack="material"
              fill={ThemeColor.yellow}
            />
          </View>

          <View style={[ThemeStyle.spacingLeftSmall, ThemeStyle.flex1]}>
            <Progress.Bar
              progress={totalRatePercentage}
              height={3}
              width={null}
              color={ThemeColor.green}
            />
          </View>
        </View>
      );
    });
  };
  if (status === 'empty') {
    return null;
  }
  ///show loader for ratings header
  if (lodashIsUndefined(rating_summary)) {
    return (
      <Layout
        dataSet={{
          media: constants.isWeb ? ids.mainWrapper : ids.mainWrapperMobile,
        }}
        style={constants.isWeb ? styles.mainWrapper : styles.mainWrapperMobile}
      >
        <View style={[styles.webReviewContainer, { rowGap: 10 }]}>
          {numberToArray(5).map((_, i) => (
            <View
              key={i}
              style={[
                ThemeStyle.flexDirectionRowCenterSpaceBetween,
                !constants.isWeb && ThemeStyle.pageVerticalSpacingSmall,
              ]}
            >
              <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
                <View
                  style={[
                    ThemeStyle.flexDirectionRowCenterSpaceBetween,
                    {
                      width: 30,
                    },
                  ]}
                >
                  <RectangleLoader width={25} height={10} />
                </View>

                <View style={ThemeStyle.spacingLeftSmall}>
                  <RectangleLoader width={300} height={10} />
                </View>
              </View>
            </View>
          ))}
        </View>

        <View
          dataSet={{
            media: constants.isWeb
              ? ids.overviewRatingsContainer
              : ids.mobileOverviewRatingsContainer,
          }}
          style={[
            constants.isWeb
              ? styles.overviewRatingsContainer
              : styles.mobileOverviewRatingsContainer,
            { rowGap: 10 },
          ]}
        >
          <RectangleLoader width={100} height={20} />

          <View style={!constants.isWeb && ThemeStyle.pageVerticalSpacingSmall}>
            <RectangleLoader width={100} height={20} />
          </View>

          <View style={[ThemeStyle.flexDirectionRowCenter, { columnGap: 2 }]}>
            <RectangleLoader width={70} height={20} />
            {!constants.isWeb && <View style={{ width: 5 }} />}
            <RectangleLoader width={30} height={20} />
          </View>
        </View>
      </Layout>
    );
  }

  return (
    <Fragment>
      <Layout
        dataSet={{
          media: constants.isWeb ? ids.mainWrapper : ids.mainWrapperMobile,
        }}
        style={constants.isWeb ? styles.mainWrapper : styles.mainWrapperMobile}
      >
        <View style={styles.webReviewContainer}>
          <FilterRatingStar />
        </View>

        <View
          dataSet={{
            media: constants.isWeb
              ? ids.overviewRatingsContainer
              : ids.mobileOverviewRatingsContainer,
          }}
          style={
            constants.isWeb
              ? styles.overviewRatingsContainer
              : styles.mobileOverviewRatingsContainer
          }
        >
          <Text category="h4">{average.toFixed(2)}</Text>
          <View>
            <RatingStar
              starValue={average}
              starSize={15}
              style={{ marginTop: 0, marginLeft: -5 }}
              view
              disabled
            />
          </View>

          <View style={[ThemeStyle.flexDirectionRowCenter, { columnGap: 2 }]}>
            <Text category={constants.isWeb ? 'c1' : 'p2'}>
              {parseFloat(total)} reviews
            </Text>
            <Button
              style={!constants.isWeb && ThemeStyle.spacingLeftSmall}
              onPress={toggleShowNote}
              plain
            >
              <Icon
                name="info-outline"
                fill={ThemeColor.green}
                style={styles.noteIcon}
              />
            </Button>
          </View>
        </View>
      </Layout>
    </Fragment>
  );
};

const { ids, styles } = StyleSheet.create({
  mainWrapper: {
    ...ThemeStyle.spacingBottom,
    borderRadius: 5,
    padding: 10,
    [`@media ${BreakPoints.xs}`]: {
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.sm}`]: {
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.md}`]: {
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.lg}`]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xl}`]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xxl}`]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
  mainWrapperMobile: {
    borderRadius: 5,
    padding: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  webReviewContainer: {
    ...ThemeStyle.flex2,
    paddingRight: 5,
  },
  overviewRatingsContainer: {
    ...ThemeStyle.flex1,
    [`@media ${BreakPoints.xs}`]: {
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    [`@media ${BreakPoints.sm}`]: {
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    [`@media ${BreakPoints.md}`]: {
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    [`@media ${BreakPoints.lg}`]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    [`@media ${BreakPoints.xl}`]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    [`@media ${BreakPoints.xxl}`]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  mobileOverviewRatingsContainer: {
    ...ThemeStyle.flex1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noteIcon: {
    width: 15,
    height: 15,
  },
  iconSize: { width: 15, height: 15 },
  filterLabel: { marginLeft: constants.isWeb ? 5 : 0 },
  filterProgress: {
    marginHorizontal: 5,
    height: 4,
    borderRadius: 2,
    backgroundColor: ThemeColor.green,
  },
  filterRatingsContainer: {
    ...ThemeStyle.flex1,
    columnGap: 10,
    rowGap: 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  filterButtonStyle: {
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
  },
  filterButtonContentStyle: {
    padding: 10,
  },
  activeFilterButton: {
    backgroundColor: ThemeColor.green,
  },
  inactiveFilterButton: {
    backgroundColor: ThemeColor.white,
    borderWidth: 1,
    borderColor: ThemeColor.gray400,
  },
  activeFilterButtonText: {
    backgroundColor: ThemeColor.white,
  },
  inactiveFilterButtonText: {
    backgroundColor: ThemeColor.black,
  },
});

export default memo(Rating);
