import { Text } from '@ui-kitten/components';
import { Fragment, useEffect, useState } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import lodashIsUndefined from 'lodash/isUndefined';
import constants from '../../../../Config/constants';

import validation from '../../../../Config/validationRules';

import ThemeStyle from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import Button from '../../../Button';

import ModalPrompt from './ModalPrompt';
import useModalPrompt from './hooks/useModalPrompt';
import Form from '../../../FormFields/Form';
import FormField from '../../../FormFields/FormField';
import SubmitButton from '../../../FormFields/SubmitButton';

const validationSchema = validation.createSchema({
  distance: validation.distanceSchema,
});

const formInitialValues = {
  distance: constants.DEFAULT_DISTANCE,
};

const DistanceSelector = ({ modalId }) => {
  const { hideModalPrompt } = useModalPrompt();
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (!lodashIsUndefined(data?.value) && data?.value !== '10') {
      formInitialValues.distance = data.value;
    }
  }, [data?.value]);

  const _onClose = () => {
    hideModalPrompt(modalId, {});
  };

  const onSubmit = (data: any) => {
    hideModalPrompt(modalId, { value: data?.distance });
  };
  return (
    <ModalPrompt
      id={modalId}
      title={'Set store distance'}
      dataSet={{ media: ids.distanceSelectorModal }}
      mainContentWrapperStyle={styles.distanceSelectorModal}
      hasHeader
      hasHeaderLine
      onCloseButtonClick={_onClose}
      closeOnDocumentClick={false}
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <View>
        <Text category="p1" style={ThemeStyle.spacingBottomMedium}>
          Min. {constants.DEFAULT_DISTANCE}
          {constants.DISTANCE_UNIT}
        </Text>

        <Form
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors }) => {
            return (
              <Fragment>
                <FormField
                  direction="column"
                  type="number"
                  label="Distance"
                  name="distance"
                  testID="distance"
                  placeholder={`e.g: 10 (10 ${constants.DISTANCE_UNIT})`}
                  withBackground
                />

                <View
                  style={[
                    ThemeStyle.spacingBottomMedium,
                    errors?.distance && ThemeStyle.spacingTopMedium,
                  ]}
                >
                  <SubmitButton
                    text="View Results"
                    withShadow
                    style={{ marginTop: 0 }}
                  />
                </View>
                <Button
                  status="control"
                  onPress={() => {
                    onSubmit({ distance: constants.DEFAULT_DISTANCE });
                  }}
                  disabled={values?.distance === constants.DEFAULT_DISTANCE}
                >
                  Reset
                </Button>
              </Fragment>
            );
          }}
        </Form>
      </View>
    </ModalPrompt>
  );
};
const { ids, styles } = StyleSheet.create({
  distanceSelectorModal: {
    [`@media ${BreakPoints.xs}`]: {
      width: 380,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 420,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 440,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 460,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 480,
    },
  },
});
export default DistanceSelector;
