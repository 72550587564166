import React from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { Text, Icon, Spinner, useTheme } from '@ui-kitten/components';
import { useSelector } from 'react-redux';

import { shopSelector } from '../../../RTK/shop/selectors';

import CartHelper from '../../../Helper/Cart';

import useCart from '../../../Hooks/useCart';

import Service from '../../../Service';

import ThemeStyle from '../../../Theme/styles';
import ThemeColor from '../../../Theme/colors';

import Button from '../../Button';
import Image from '../../Image';
import { RectangleLoader } from '../../Loader';

import { CartDetails } from '../../../Types';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  isUpdatingItem: boolean;
  item: CartDetails;
  decrementPress: () => void;
  incrementPress: () => void;
  removeThisItem: () => void;
  isUnavailable: boolean;
  onItemPress: () => void;
};

const CartItem = ({
  disabled,
  isLoading,
  isUpdatingItem,
  item,
  decrementPress,
  incrementPress,
  removeThisItem,
  isUnavailable = false,
  onItemPress,
}: Props) => {
  const { isCanReschedule } = useCart();
  const shopData = useSelector(shopSelector);
  const { image, name, extras, price, quantity } = item;

  const extrasValue = extras?.map((e) => e.name).join(', ') || '';

  const totalPrice = CartHelper.getTotal({ price, quantity, extras });
  //@ts-ignore: JS code error
  const canAdd = isCanReschedule(shopData?.id, item?.id);
  const store_theme = useTheme();
  const isUnavailableOrUpdatingItem = isUnavailable || isUpdatingItem;

  return !isLoading ? (
    <View style={ThemeStyle.flex1}>
      <Pressable
        onPress={onItemPress}
        style={styles.wrapper}
        disabled={disabled}
      >
        {/* Overlay for unavailable */}
        {isUnavailableOrUpdatingItem && (
          <View
            style={[
              ThemeStyle.pageBackground,
              { opacity: 0.5, zIndex: 1, ...StyleSheet.absoluteFillObject },
            ]}
          />
        )}
        {
          //@ts-ignore
          <Image
            source={{ uri: image }}
            style={styles.image_size}
            resizeMode="cover"
          />
        }

        <View style={styles.details_container}>
          <Text category="c1">{name}</Text>

          <Text category="c1" numberOfLines={2}>
            {extrasValue}
          </Text>

          <Text category="c1">{Service.commafyNumber(totalPrice, true)}</Text>

          {/* Unavailable text */}
          {isUnavailable && (
            <Text
              category="c2"
              style={[ThemeStyle.noticeText, ThemeStyle.bold]}
            >
              Unavailable
            </Text>
          )}
        </View>

        {isUnavailable ? (
          <Button
            onPress={removeThisItem}
            style={styles.itemRemoveButton}
            plain
          >
            <Icon
              name={'trash'}
              fill={ThemeColor.red}
              pack="feather"
              style={styles.icon_size}
            />
          </Button>
        ) : (
          <View style={styles.qty_container}>
            <Button
              onPress={decrementPress}
              style={styles.control_btn_container}
              disabled={disabled || isUpdatingItem}
              plain
            >
              <Icon
                name={quantity > 1 ? 'minus' : 'trash'}
                fill={store_theme['icon_color'] || ThemeColor.green}
                pack="feather"
                style={styles.icon_size}
              />
            </Button>

            <View style={styles.qty_txt_holder}>
              {isUpdatingItem && (
                <View
                  style={[
                    StyleSheet.absoluteFill,
                    ThemeStyle.alignItemsCenter,
                    ThemeStyle.justifyContentCenter,
                  ]}
                >
                  <Spinner size="large" status="success" />
                </View>
              )}
              <Text category="c1">{`${quantity}x`}</Text>
            </View>

            <Button
              onPress={incrementPress}
              disabled={!canAdd || disabled || isUpdatingItem}
              style={styles.control_btn_container}
              plain
            >
              <Icon
                name={'plus'}
                fill={store_theme['icon_color'] || ThemeColor.green}
                pack="feather"
                style={styles.icon_size}
              />
            </Button>
          </View>
        )}
      </Pressable>
    </View>
  ) : (
    <View style={styles.loading_skeleton_contaier}>
      {/*
        left
        */}
      <View style={styles.first_column}>
        <RectangleLoader height={50} />
      </View>
      {/*
        center
        */}
      <View style={styles.second_column}>
        <View style={ThemeStyle.spacingTopSmall}>
          <RectangleLoader height={20} />
        </View>
        <View style={ThemeStyle.spacingTopSmall}>
          <RectangleLoader height={20} />
        </View>
        <View style={ThemeStyle.spacingTopSmall}>
          <RectangleLoader height={20} />
        </View>
      </View>
      {/*
        right
        */}
      <View style={styles.third_column}>
        <RectangleLoader height={50} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    ...ThemeStyle.pageVerticalSpacingMedium,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  image_size: {
    ...ThemeStyle.spacingRightMedium,
    borderRadius: 5,
    width: 40,
    height: 40,
  },
  details_container: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.spacingRightMedium,
    justifyContent: 'center',
  },

  qty_container: {
    ...ThemeStyle.regular,
    backgroundColor: ThemeColor.lightGray,
    borderRadius: 14,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon_size: { width: 15, height: 15 },
  qty_txt_holder: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  control_btn_container: {
    backgroundColor: ThemeColor.white,
    width: 30,
    height: 30,
    borderRadius: 60,
    alignItems: 'center',
    justifyContent: 'center',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 6,
  },
  loading_skeleton_contaier: {
    ...ThemeStyle.flexDirectionRowSpaceBetween,
    ...ThemeStyle.alignItemsCenter,
  },
  first_column: {
    ...ThemeStyle.spacingBottomSmall,
    width: '25%',
  },
  second_column: {
    ...ThemeStyle.spacingBottomSmall,
    width: '40%',
  },
  third_column: {
    ...ThemeStyle.spacingBottomSmall,
    width: '25%',
  },
  itemRemoveButton: {
    ...ThemeStyle.pageVerticalSpacingSmall,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    backgroundColor: ThemeColor.white,
    borderRadius: 10,
    zIndex: 2,
  },
});

export default CartItem;
