import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import lodashPick from 'lodash/pick';

import QuantitySelector from '../../Components/QuantitySelector';
import useSchedulePicker from '../../Components/SchedulePicker/index.controller';
import CartHelper from '../../Helper/Cart';
import { allCartSelector } from '../../RTK/cart/selectors';
import { updateQuantity } from '../../RTK/product';
import { productSelector } from '../../RTK/product/selectors';
import { whenFilterSelector } from '../../RTK/filter/selectors';
import ThemeStyle from '../../Theme/styles';

import constants from '../../Config/constants';

import { specialInstructionDefaultValues } from './SpecialInstruction';
import ScreenSize from '../../Helper/ScreenSize';

function ProductFooter({
  cartData,
  mealPlan,
  productData,
  stocks,
  dispatchUpdateQuantity,
  ...rest
}) {
  const { xs } = ScreenSize();
  const { setRescheduleStoreId } = useSchedulePicker();

  const existingCartItem = CartHelper?.getCartItem(
    productData?.store?.id,
    productData?.id,
    productData?.extras || [],
    productData?.instructions || specialInstructionDefaultValues,
    cartData
  );
  const webProps = lodashPick(rest, [
    'totalPrice',
    'isAvailable',
    'disabled',
    'addToCartPress',
  ]);

  const _onQtyChange = (qty) => dispatchUpdateQuantity(qty);

  return (
    <Fragment>
      <View style={ThemeStyle.sectionSeparator} />

      <View
        style={constants.isWeb && xs ? styles.wrapperSmall : styles.wrapper}
      >
        <QuantitySelector
          // common props
          isUpdate={productData?.isUpdate}
          mealPlan={mealPlan}
          // limit reached props
          productId={productData?.id}
          storeId={productData?.store?.id}
          stocks={stocks}
          onReschedule={() =>
            constants.isWeb
              ? rest?.toggleDateTimePicker()
              : setRescheduleStoreId(productData.store.id)
          }
          // selector props
          value={productData?.quantity || 1}
          valueOnCart={existingCartItem?.quantity}
          onChange={_onQtyChange}
          {...webProps}
        />
      </View>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  wrapper: [
    ThemeStyle.pageHorizontalSpacing,
    ThemeStyle.alignItemsCenter,
    ThemeStyle.spacingBottomMedium,
  ],
  wrapperSmall: [ThemeStyle.pageHorizontalSpacing, ThemeStyle.alignItemsCenter],
});

const mapStateToProps = (state) => ({
  cartData: allCartSelector(state),
  productData: productSelector(state),
  when: whenFilterSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateQuantity: (data) => dispatch(updateQuantity(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductFooter);
