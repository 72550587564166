import { StyleSheet } from 'react-native';

import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

const menuSize = 25;

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ThemeColor.white,
  },
  menuWrapper: {
    position: 'absolute',
    zIndex: 2,
  },
  menuContainer: {
    ...ThemeStyle.pageHorizontalSpacing,
    paddingTop: constants.isIOS ? 7 : 8,
    paddingBottom: constants.isIOS ? 7 : 8,
    paddingRight: globalSpacing / 2,
    zIndex: 1,
  },
  menuIcon: {
    width: menuSize,
    height: menuSize,
  },
  tabBarStyle: {
    position: 'absolute',
    paddingLeft: 55,
    backgroundColor: ThemeColor.white,
    width: '100%',
    borderBottomColor: ThemeColor.gray,
    borderBottomWidth: 1,
    zIndex: 1,
  },
  notAcceptingAdvanceOrderContainer: { backgroundColor: ThemeColor.red100 },
  notAcceptingOrderTxt: { textAlign: 'center', ...ThemeStyle.bold },
});
