import React, { memo, useMemo, useRef, useState } from 'react';
import { View, ScrollView } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Layout, Text, useTheme } from '@ui-kitten/components';

import lodashIsEmpty from 'lodash/isEmpty';
import Tooltip from 'rn-tooltip';

import useError from '../../../../Hooks/useError';

import routeList from '../../../../Routes/list';

import MenuItemHelper from '../../../../Helper/MenuItem';

import TagIconList from '../../../../Service/TagIconList';

import Badge from '../../../../Components/Badge';
import Button from '../../../../Components/Button';
import Image from '../../../../Components/Image';

import ThemeStyle, { deviceHeight } from '../../../../Theme/styles';
import ThemeColors from '../../../../Theme/colors';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import ExtraGroup from '../../../../Screens/Product/ExtraGroup';
import ProductFooter from '../../../../Screens/Product/ProductFooter';

import useController from '../../../../Screens/Product/controller';

import constants from '../../../../Config/constants';
import messages from '../../../../Config/messages';

import useToast from '../../../../Hooks/useToast';

import { productSelector } from '../../../../RTK/product/selectors';

import CloseButton from '../../CloseButton';
import ReadMore from '../../../Readmore';
import ModalPrompt from './ModalPrompt';
import { MODALPROMPT } from './config';
import { CartDetails } from '../../../../Types';
import useModalPrompt from './hooks/useModalPrompt';
import { changeWhenFilter } from '../../../../RTK/filter';
import { shopSelector } from '../../../../RTK/shop/selectors';
import { isLoggedInSelector } from '../../../../RTK/user/selectors';

//@ts-ignore: JS Code error
import { RAZZLE_BUILD_MODE } from '@env';

interface ItemProps {
  item: CartDetails;
  route?: any;
}
const Item = () => {
  const store_theme = useTheme();

  const dispatch = useDispatch();

  const { showModalPrompt, hideModalPrompt } = useModalPrompt();

  const toast = useToast();
  const bulkToolTip = useRef(null);
  const scrollList = useRef<ScrollView>();
  const shopData = useSelector(shopSelector);
  const productData = useSelector(productSelector);

  const isLogin = useSelector(isLoggedInSelector);
  const [data, setData] = useState<ItemProps>();

  const { item, route, navigation } = data || {
    item: {
      item_id: '',
      image: '',
      name: '',
      description: '',
      tags: [],
      is_exclusive: false,
      is_bulk: false,
      isUpdate: false,
    },
  };
  const {
    item_id,
    image,
    name,
    description,
    tags,
    is_exclusive,
    is_bulk,
    isUpdate,
    storeName,
    storeImage,
  } = item;

  const availableExtras = productData?.extra_group;
  const { ErrorCartOrCheckoutHandler } = useError(shopData?.id);
  const {
    state,
    clearProductData,
    getProductData,
    getItemTotalPrice,
    addOrRemoveExtras,
    toggleExtrasErrorPrompt,
    getSelectedExtra,
    onAddToCartPressed,
    errorMessage,
    setErrorMessage,
    getMealPlanProps,
    handleEditCart,
  } = useController({
    params: {
      id: item_id,
      is_exclusive: is_exclusive || false,
      ...item,
      isUpdate: isUpdate || false,
    },
  });

  const itemUnavailable = MenuItemHelper.isItemUnavailable(
    !lodashIsEmpty(productData) ? productData : item
  );

  useMemo(() => {
    getProductData();
    return () => {
      clearProductData();
    };
  }, [data?.item]);

  const _renderTagBelowItemName = () => {
    // prettier-ignore
    const showTagBelowName = itemUnavailable || is_bulk

    if (!showTagBelowName) {
      return null;
    }

    return (
      <View style={[ThemeStyle.flexDirectionRow]}>
        {is_bulk && (
          <View
            style={[
              ThemeStyle.spacingBottomSmall,
              ThemeStyle.spacingRightMedium,
            ]}
          >
            {
              //@ts-ignore: JS code error
              <Tooltip
                ref={bulkToolTip}
                width={200}
                height={50}
                withOverlay={false}
                containerStyle={{
                  borderRadius: 5,
                  padding: 5,
                  backgroundColor: ThemeColors.white,
                  ...ThemeStyle.shadow,
                }}
                pointerColor={ThemeColors.gray}
                popover={
                  <Text>
                    This item is bulky and can only be delivered by cars
                  </Text>
                }
              >
                <Button
                  onPress={() => bulkToolTip.current.toggleTooltip()}
                  hitSlop={30}
                  plain
                >
                  <Icon
                    name={TagIconList.fontAwesomeIcon.bulk}
                    pack="fa5"
                    fill={ThemeColors.orange}
                    style={{ width: 18, height: 18 }}
                  />
                </Button>
              </Tooltip>
            }
          </View>
        )}
        {!lodashIsEmpty(productData) && itemUnavailable ? (
          <View
            style={[
              ThemeStyle.spacingBottomSmall,
              ThemeStyle.spacingRightSmall,
            ]}
          >
            {
              //@ts-ignore: JS code error
              <Badge
                size={'c1'}
                type={Badge.TYPES.SOLID}
                category={Badge.CATEGORIES.DANGER}
              >
                Unavailable
              </Badge>
            }
          </View>
        ) : null}
      </View>
    );
  };

  const _renderTagBelow = () => {
    if (tags?.length === 0) {
      return null;
    }

    return (
      <View style={ThemeStyle.spacingLeftMedium}>
        <View
          style={[
            ThemeStyle.spacingLeftMedium,
            ThemeStyle.flexDirectionRow,
            { flexWrap: 'wrap', flexShrink: 1 },
          ]}
        >
          {tags?.map((t, i) => {
            const result: {
              id: string;
              type: string;
              category: string;
              icon: string;
              name: string;
            } = TagIconList.getTagIcon(t);
            return (
              <View
                style={[
                  ThemeStyle.spacingBottomSmall,
                  ThemeStyle.spacingRightSmall,
                ]}
                key={`${item_id}-${i}`}
              >
                {
                  //@ts-ignore: JS code error
                  <Badge
                    size={'c1'}
                    type={result.type}
                    category={result.category}
                  >
                    {`${result.icon} ${result.name}`}
                  </Badge>
                }
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  const _getLimit = () => {
    //@ts-ignore: JS code error
    const stock = MenuItemHelper.getCategoryOrItemStock(item);
    if (stock) {
      return (
        <Text category="p2" status="danger">
          {stock} items left!
        </Text>
      );
    }
    //@ts-ignore: JS code error
    return item.heading;
  };

  const _extras = (item, index) => {
    let defaultSelected = state.isUpdate ? getSelectedExtra(item) : null;
    const isRequired = item?.is_required;

    if (lodashIsEmpty(item)) {
      return null;
    }

    return (
      <>
        <ExtraGroup
          store_theme={store_theme}
          defaultSelected={defaultSelected}
          group={item}
          onGroupSelection={addOrRemoveExtras}
          onError={toggleExtrasErrorPrompt}
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            state.extrasCoords[index] = layout.y;
          }}
        />
        {errorMessage && isRequired && (
          <View style={styles.errorTags}>
            <Text category="p2" status="danger">
              {errorMessage}
            </Text>
          </View>
        )}
      </>
    );
  };

  const _onPressClosed = () => {
    setErrorMessage('');
    hideModalPrompt(MODALPROMPT.itemModal);
  };

  const _onPressToggleDateTimePicker = async () => {
    //show datepicker
    if (shopData.is_accepting_in_advanced_orders) {
      const result = await showModalPrompt(
        MODALPROMPT.changeDateAndTimeSchedule,
        {}
      );
      if (!lodashIsEmpty(result)) {
        await dispatch(changeWhenFilter(result));
        toast.show(
          `${messages.CHANGE_SCHEDULE} ${result.label || 'ASAP'}.`,
          true
        );
      }
    } else {
      const result = await showModalPrompt(MODALPROMPT.changeTimeSchedule, {});
      if (!lodashIsEmpty(result)) {
        await dispatch(changeWhenFilter(result));
        toast.show(
          `${messages.CHANGE_SCHEDULE} ${result.label || 'ASAP'}.`,
          true
        );
      }
    }
  };

  const onDone = (data) => {
    if (data.type === 'Success') {
      if (route?.name === routeList.SEARCH) {
        toast.show(messages.ADDED_CART_ITEM, true);
      }
      return hideModalPrompt(MODALPROMPT.itemModal);
    } else {
      return ErrorCartOrCheckoutHandler({
        error: data,
        isAcceptingAdvanceOrder: shopData?.is_accepting_in_advanced_orders,
        store_id: item.store_id,
      });
    }
  };

  const _renderListItem = (
    <Layout level="1">
      {!lodashIsEmpty(storeName) && (
        <View style={styles.storeHeader}>
          <Image
            source={{
              uri: storeImage,
            }}
            style={styles.storeImage}
          />
          <Text
            category="s1"
            numberOfLines={1}
            style={ThemeStyle.spacingRightSmall}
          >
            {storeName}
          </Text>

          <View style={styles.storeCloseContainer}>
            <CloseButton onPress={_onPressClosed} />
          </View>
        </View>
      )}

      <View style={styles.img_container}>
        {
          //@ts-ignore: JS code error
          <Image
            source={{ uri: image }}
            resizeMode="cover"
            style={styles.img_size}
          />
        }
        {lodashIsEmpty(storeName) && (
          <View style={styles.closeButtonContainer}>
            <CloseButton onPress={_onPressClosed} />
          </View>
        )}
      </View>

      <View
        style={{
          ...ThemeStyle.flexDirectionRow,
          ...ThemeStyle.pageHorizontalSpacing,
          ...ThemeStyle.spacingTopSmall,
          columnGap: 12,
        }}
      >
        <Text category="s1" numberOfLines={1}>
          {name}
        </Text>
        {_renderTagBelowItemName()}
      </View>

      {_renderTagBelow()}

      <View style={styles.descriptionContainer}>
        {
          //@ts-ignore: JS code error
          <ReadMore numberOfLines={2} value={description} />
        }
      </View>
      <View style={styles.description_tags}>{_getLimit()}</View>
    </Layout>
  );

  const _addToCartPress = () => {
    if (!isLogin && RAZZLE_BUILD_MODE !== 'branded') {
      hideModalPrompt(MODALPROMPT.itemModal);
      showModalPrompt(MODALPROMPT.authentication, {
        isLogin: true,
        navigation: navigation,
      });
      return;
    }

    onAddToCartPressed(scrollList.current, onDone);
  };

  return (
    <ModalPrompt
      id={MODALPROMPT.itemModal}
      title=""
      dataSet={{ media: ids.mainWrapper }}
      mainContentWrapperStyle={styles.mainWrapper}
      childContentContainerStyle={{
        paddingHorizontal: 0,
        flex: 2,
      }}
      hasHeader={false}
      hasHeaderLine={false}
      closeOnDocumentClick={false}
      footer={
        <View style={{ flex: 1 }}>
          <ProductFooter
            stocks={state.stocks}
            disabled={state.isAddingOrUpdatingItemButton}
            isAvailable={!state.submitting && productData}
            totalPrice={getItemTotalPrice}
            productData={productData}
            addToCartPress={_addToCartPress}
            toggleDateTimePicker={_onPressToggleDateTimePicker}
            {...getMealPlanProps()}
          />
        </View>
      }
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <ScrollView
        ref={scrollList}
        showsVerticalScrollIndicator={true}
        dataSet={{ media: ids.scrollWrapper }}
        contentContainerStyle={styles.scrollWrapper}
        scrollEventThrottle={constants.scrollThrottle}
        onContentSizeChange={() => {
          if (lodashIsEmpty(errorMessage)) {
            return scrollList.current?.scrollTo({ y: 0, animated: true });
          }
        }}
      >
        {_renderListItem}
        {availableExtras?.map(_extras)}
      </ScrollView>
    </ModalPrompt>
  );
};

const { ids, styles } = StyleSheet.create({
  mainWrapper: {
    [`@media ${BreakPoints.xs}`]: {
      width: '95%',
      marginHorizontal: '2.5%',
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 550,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 550,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 550,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 550,
    },
    '@media (min-height: 400px) and (max-height: 599px)': {
      maxHeight: 450,
    },
    '@media (min-height: 600px) and (max-height: 799px)': {
      maxHeight: 550,
    },
    '@media (min-height: 800px) and (max-height: 999px)': {
      maxHeight: 650,
    },
  },
  childContentContainerStyle: {
    paddingHorizontal: 0,
    paddingTop: 0,
  },
  contentWrapper: {
    height: 450,
  },
  headerStyle: {
    marginRight: 5,
  },
  listContainer: {
    height: 430,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    overflow: 'hidden',
  },
  listContainerLandscape: {
    height: 150,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    overflow: 'hidden',
  },
  scrollWrapper: {
    ...ThemeStyle.pageBottomSpace,
    ...ThemeStyle.pageBackground,
    [`@media ${BreakPoints.xs}`]: {
      width: '100%',
    },
    [`@media ${BreakPoints.sm}`]: {
      width: '100%',
    },
    [`@media ${BreakPoints.md}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 'auto',
    },
  },
  closeButtonContainer: {
    position: 'absolute',
    alignSelf: 'flex-end',
    ...ThemeStyle.spacingTopSmall,
    ...ThemeStyle.spacingRightSmall,
  },
  descriptionContainer: {
    flex: 1,
    ...ThemeStyle.spacingTopSmall,
    ...ThemeStyle.pageHorizontalSpacing,
  },
  descriptionTxt: { fontSize: 14 },
  description_tags: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.pageVerticalSpacingSmall,
  },
  errorTags: {
    maxWidth: 550,
    ...ThemeStyle.spacingLeft,
    ...ThemeStyle.spacingRight,
    ...ThemeStyle.pageVerticalSpacingSmall,
  },
  img_container: {
    width: '100%',
    height: 250,
    alignItems: 'center',
  },
  img_size: { width: '100%', height: '100%' },
  controlContainer: {
    flexDirection: 'row',
  },
  addToCartButton: {
    height: 40,
    borderRadius: 120,
    borderColor: ThemeColors.green,
    borderWidth: 1,
    backgroundColor: ThemeColors.green,
    alignItems: 'center',
    justifyContent: 'center',
  },
  extrasContainer: {
    [`@media ${BreakPoints.xs}`]: {
      width: '100%',
    },
    [`@media ${BreakPoints.sm}`]: {
      width: '100%',
    },
    [`@media ${BreakPoints.md}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 'auto',
    },
  },
  storeHeader: {
    ...ThemeStyle.flexDirectionRowCenter,
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.spacingTopMedium,
    ...ThemeStyle.spacingBottomMedium,
    columnGap: 16,
  },
  storeImage: {
    width: 45,
    height: 45,
    borderRadius: 45,
  },
  storeCloseContainer: {
    position: 'absolute',
    right: 20,
  },
});

export default memo(Item);
