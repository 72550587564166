import React from 'react';
import { ScrollView, View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { Text } from '@ui-kitten/components';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import validation from '../../../Config/validationRules';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';
import BreakPoints from '../../../Theme/styles/breakpoints';

import Button from '../../../Components/Button';

import Form from '../../../Components/FormFields/Form';
import Footer from '../../../Components/Footer';
import SubmitButton from '../../../Components/FormFields/SubmitButton';
import ProfileForm from '../../../Components/ProfileForm';

import useCancellableRequest from '../../../Hooks/useCancellableRequest';
import withController from '../../../Hooks/withController';
import useProfileController from './controller';

const validationSchema = validation.createSchema({
  firstName: validation.firstNameSchema,
  lastName: validation.lastNameSchema,
  phone: validation.phoneSchema,
  email: validation.emailSchema,
});

const formInitialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};

const Profile = () => {
  const { user } = useSelector((state: any) => state.user);
  formInitialValues.firstName = user?.first_name;
  formInitialValues.lastName = user?.last_name;
  formInitialValues.phone = user?.phone?.replace('+63', '');
  formInitialValues.email = user?.email;

  const { onSubmit } = useProfileController();

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        dataSet={{ media: ids.mainWrapper }}
        style={styles.mainWrapper}
      >
        <View dataSet={{ media: ids.containerBox }} style={styles.containerBox}>
          <View style={ThemeStyle.spacingBottomMedium}>
            <Text category="s1">Personal Information</Text>
          </View>

          <Form
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange={true}
          >
            <ProfileForm useFormikContext />

            <View style={styles.submitButtonContainer}>
              <SubmitButton text="Save" withShadow />
            </View>
          </Form>
        </View>

        <View style={styles.footerWrapper}>
          <Footer />
        </View>
      </ScrollView>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  mainWrapper: {
    ...ThemeStyle.pageBackground,
    paddingVertical: 20,
    [`@media ${BreakPoints.xs}`]: {
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingHorizontal: 100,
    },
    [`@media ${BreakPoints.md}`]: {
      paddingHorizontal: 100,
    },
    [`@media ${BreakPoints.lg}`]: {
      paddingHorizontal: 100,
    },
    [`@media ${BreakPoints.xl}`]: {
      paddingHorizontal: 100,
    },
    [`@media ${BreakPoints.xxl}`]: {
      paddingHorizontal: 100,
    },
  },
  containerBox: {
    padding: 20,
    borderWidth: 1,
    borderColor: ThemeColor.gray,
    ...ThemeStyle.spacingBottom,
    [`@media ${BreakPoints.xs}`]: {},
    [`@media ${BreakPoints.sm}`]: {
      alignSelf: 'center',
      width: 500,
    },
    [`@media ${BreakPoints.md}`]: {
      alignSelf: 'center',
      width: 550,
    },
    [`@media ${BreakPoints.lg}`]: {
      alignSelf: 'center',
      width: 600,
    },
    [`@media ${BreakPoints.xl}`]: {
      alignSelf: 'center',
      width: 650,
    },
    [`@media ${BreakPoints.xxl}`]: {
      alignSelf: 'center',
      width: 700,
    },
  },
  footerWrapper: {
    ...ThemeStyle.pageBackground,
  },
  submitButtonContainer: { width: '40%', alignSelf: 'center' },
});

export default withController(Profile, useCancellableRequest, 'api');
