import { createSlice } from '@reduxjs/toolkit';
import lodashFindIndex from 'lodash/findIndex';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsUndefined from 'lodash/isUndefined';

import constants from '../../Config/constants';

const gettingUserDataInitialState = {
  loading: true, // default to true, since user data must be fetch first
  error: '',
};

const initialState = {
  showDatePicker: false,
  showMealPlanHint: true,
  orderDetails: undefined, // used for storing temporarily the order details data to navigate on next screen
  gettingUserData: {
    details: gettingUserDataInitialState,
    address: gettingUserDataInitialState,
    cart: gettingUserDataInitialState,
  },
  settings: {
    savedPayment: [
      { native: true, name: constants.isIOS ? 'Apple Pay' : 'Google Pay' },
    ],
    notification: {
      all: true,
      orders: true,
      promotion: false,
      sms: false,
    },
  },
  rescheduleStoreId: '',
  // for order banner
  hasOrderBanner: false,
  isOrderBannerMinimized: false,
  // for basket tab
  highlightOrderId: '', // order_id or "first"
  scrollCartToStoreId: '',
  // value e.g: { id: "store id", orderId: "order id" }
  successfulOrder: [], // storing of store id and order id successful order to show to this id only the verifying payment
  loadAllStoresAndActiveOrders: false, // to control when to load the all stores & active orders (should load first the funnels)
};

export const utilitySlice = createSlice({
  name: 'utility',
  initialState,
  reducers: {
    addPaymentMethod: (state, action) => {
      state.settings.savedPayment = [
        ...state.settings.savedPayment,
        action.payload,
      ];
    },
    addSuccessfulOrder: (state, action) => {
      const storeId = action.payload; // action.payload is store id
      if (lodashIsUndefined(state.successfulOrder)) {
        // if utility redux is already has persisting value, successfulOrder is usually undefined even we set initial value for it
        state.successfulOrder = []; // set initial value
      }
      const existingIndex = lodashFindIndex(
        state.successfulOrder,
        (e) => e.storeId === storeId
      );
      if (existingIndex === -1) {
        // if existingIndex is -1 it means, no existing successful order on that order id
        state.successfulOrder.push({ id: storeId });
      }
    },
    setLoadAllStoresAndActiveOrders: (state, action) => {
      state.loadAllStoresAndActiveOrders = action.payload;
    },
    removeSuccessfulOrder: (state, action) => {
      const successfulOrderIndex = lodashFindIndex(
        state.successfulOrder,
        (e) => e.id === action.payload // store id
      );
      state.successfulOrder.splice(successfulOrderIndex, 1);
    },
    setFirstMealPlanCheckout: (state) => {
      state.showMealPlanHint = false;
    },
    setGettingUserData: (state, action) => {
      // key = string from gettingUserData key on initial state or undefined
      // value = object from gettingUserDataInitialState or undefined
      const key = action?.payload?.key;
      const value = action?.payload?.value || {};
      if (key && !lodashIsEmpty(value)) {
        // set key to value
        state.gettingUserData[key] = {
          ...gettingUserDataInitialState,
          ...value,
        };
      } else {
        // set all keys to:
        Object.keys(state.gettingUserData).forEach((e) => {
          if (lodashIsEmpty(value)) {
            // loading state to true
            state.gettingUserData[e] = {
              ...gettingUserDataInitialState,
              loading: true,
            };
          } else {
            // or the user defined value
            state.gettingUserData[e] = {
              ...gettingUserDataInitialState,
              ...value,
            };
          }
        });
      }
    },
    setHasOrderBanner: (state, action) => {
      state.hasOrderBanner = action.payload || false;
    },
    setHighlightOderId: (state, action) => {
      state.highlightOrderId = action.payload || '';
    },
    setOrderBannerMinimized: (state, action) => {
      state.isOrderBannerMinimized = action.payload;
    },
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    setPaymentMethodAsDefault: (state, action) => {
      const prevDefaultIndex = lodashFindIndex(state.settings.savedPayment, {
        default: true,
      });
      const toBeSetAsDefaultIndex = lodashFindIndex(
        state.settings.savedPayment,
        action.payload
      );
      if (prevDefaultIndex !== toBeSetAsDefaultIndex) {
        if (prevDefaultIndex >= 0) {
          // if greater than equal to 0 meaning it found the index on the array of saved payment
          // less than 0 is expected to be -1 it means user doesnt have selected default payment method yet
          delete state.settings.savedPayment[prevDefaultIndex].default;
        }
        state.settings.savedPayment[toBeSetAsDefaultIndex].default = true;
      }
      // do nothing if prevDefaultIndex and toBeSetAsDefaultIndex is equal,
      // meaning user is clicking the already selected as default
    },
    setRescheduleStoreId: (state, action) => {
      state.rescheduleStoreId = action.payload || '';
    },
    setScrollCartToStoreId: (state, action) => {
      state.scrollCartToStoreId = action.payload;
    },
    toggleDatePicker: (state) => {
      state.showDatePicker = !state.showDatePicker;
    },
    updateNotificationSetting: (state, action) => {
      const { key, value } = action.payload;
      state.settings.notification[key] = value;
    },
    updateSuccessfulOrder: (state, action) => {
      const { storeId, orderId } = action.payload;
      const successOrderIndex = lodashFindIndex(
        state.successfulOrder,
        (e) => e.id === storeId && typeof e.orderId === 'undefined'
      );
      if (successOrderIndex !== -1) {
        // it should always goes here when dispatched this action
        state.successfulOrder[successOrderIndex].orderId = orderId;
      }
    },
  },
});

export const {
  addPaymentMethod,
  addSuccessfulOrder,
  removeSuccessfulOrder,
  setFirstMealPlanCheckout,
  setGettingUserData,
  setHasOrderBanner,
  setHighlightOderId,
  setLoadAllStoresAndActiveOrders,
  setOrderBannerMinimized,
  setOrderDetails,
  setPaymentMethodAsDefault,
  setRescheduleStoreId,
  setScrollCartToStoreId,
  toggleDatePicker,
  updateNotificationSetting,
  updateSuccessfulOrder,
} = utilitySlice.actions;
export default utilitySlice.reducer;
