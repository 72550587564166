import React from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';
import { RAZZLE_BUILD_MODE } from '@env';

import constants from '../../Config/constants';
import { Item } from '../../types/item';
import { selectCartItemTotalQty } from '../../RTK/cart/selectors';
import { shopSelector } from '../../RTK/shop/selectors';
import { isLoggedInSelector } from '../../RTK/user/selectors';

import QuickCartPreview from './Preview';
import QuickCartFull from './Full';
import styles from './styles';

type Position = {
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
};
type Props = {
  item: Item;
  position?: Position;
  onError?: Function;
};

const QuickCart: React.FC = ({ item, position, onError }: Props) => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const storeId = useSelector((state) => shopSelector(state)?.id);
  const cartQty = useSelector((state) =>
    //@ts-ignore selector is not yet typescript file
    selectCartItemTotalQty(state, storeId, item.id)
  );
  const hasExtraGroup = !lodashIsEmpty(item.extra_group);
  const isNotInCart = cartQty <= 0;
  const showWebLoginPrompt =
    !isLoggedIn && constants.isWeb && RAZZLE_BUILD_MODE !== 'branded';
  const isPreview = (hasExtraGroup && isNotInCart) || showWebLoginPrompt;
  return (
    <View style={[styles.wrapper, position]}>
      <View style={styles.container}>
        {isPreview ? (
          <QuickCartPreview
            item={item}
            showWebLoginPrompt={showWebLoginPrompt}
          />
        ) : (
          <QuickCartFull item={item} onError={onError} />
        )}
      </View>
    </View>
  );
};

export default QuickCart;
