import { baseApi } from '../baseApi';

import { IHaveArrived, IHaveArrivedPayload } from './types';

export const orderApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    iHaveArrived: builder.mutation<
      IHaveArrived,
      { payload: IHaveArrivedPayload }
    >({
      query: (params) => ({
        url: `/user/orders/curbside/${params.payload.id}/arrived`,
        method: 'POST',
        headers: { 'x-api-version': '1' },
        body: {},
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useIHaveArrivedMutation } = orderApi;
