import { useEffect, useRef, useState } from 'react';
import { Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  filter as lodashFilter,
  find as lodashFind,
  isEmpty as lodashIsEmpty,
  omit as lodashOmit,
  isUndefined as lodashIsUndefined,
  pick as lodashPick,
} from 'lodash';

import constants from '../Config/constants';
import messages from '../Config/messages';
import { pluralize } from '../Helper';
import AnalyticsHelper from '../Helper/Analytics';
import CartHelper from '../Helper/Cart';
import CheckoutHelper from '../Helper/Checkout';
import MenuItemHelper from '../Helper/MenuItem';
import { getCartLimit, getCartTerm } from '../Helper/RemoteConfig';
import Sentry from '../Helper/Sentry';
import StoreHelper from '../Helper/Store';
import useToast from '../Hooks/useToast';
import {
  addToCart,
  removeCart,
  removeCartItem,
  setAddingOrUpdatingCart,
  syncCart,
  updateCart,
} from '../RTK/cart';
import { allCartSelector } from '../RTK/cart/selectors';
import { updateCheckoutDetails, removeCheckoutDetails } from '../RTK/checkout';
import { checkout } from '../RTK/defaultValues';
import { shopSelector, shopRawSelector } from '../RTK/shop/selectors';
import { removeSuccessfulOrder, setOrderBannerMinimized } from '../RTK/utility';
import { successfulOrderSelector } from '../RTK/utility/selectors';
import { specialInstructionDefaultValues as defaultInstructions } from '../Screens/Product/SpecialInstruction';
import Service from '../Service';
import userApi from '../Service/api/user';

import useClosedOrClosingPrompt from './useClosedOrClosingPrompt';
import useMealPlan from './useMealPlan';
import useStorePersist from './useStorePersist';

import { RAZZLE_BUILD_MODE } from '@env';
import useSchedulePicker from '../Components/SchedulePicker/index.controller';
import { MODALPROMPT } from '../Components/Web/Modal/ModalPrompt/config';
import useModalPrompt from '../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { whenFilterSelector } from '../RTK/filter/selectors';

const { ORDER_TYPES } = constants;
const fakeApiCallTimeout = 1000;
export default () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const isMounted = useRef();

  // Ensures marketplace cart is saved across sessions
  const apiSync = RAZZLE_BUILD_MODE !== 'branded';
  const { showModalPrompt } = useModalPrompt();
  const { getPrompt, showClosedOrClosingPrompt } = useClosedOrClosingPrompt();
  const { isMealPlan, addMealPlan } = useMealPlan();
  const { getCartValidationInfo, getSavedStore } = useStorePersist();
  const { setRescheduleStoreId } = useSchedulePicker();

  const cartData = useSelector(allCartSelector);
  const hasOrderBanner = useSelector((state) => state.utility.hasOrderBanner);
  const isOrderBannerMinimized = useSelector(
    (state) => state.utility.isOrderBannerMinimized
  );
  const shopData = useSelector(shopSelector);
  const shopRawData = useSelector(shopRawSelector);
  const successfulOrder = useSelector(successfulOrderSelector);
  const whenFilter = useSelector(whenFilterSelector);

  const [removing, setRemoving] = useState(false);

  const cartTerm = getCartTerm();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const _clearContinueToPaymentData = (storeId) => {
    // when user add, update or remove cart from the cart that has continue to payment link
    // remove the continue to payment data
    dispatch(
      updateCheckoutDetails({
        store_id: storeId,
        keyToUpdate: checkout.keys.CONTINUE_TO_PAYMENT,
        keyValue: undefined,
      })
    );
  };

  const _getStoreCart = (storeId) =>
    StoreHelper.getStoreCartData(storeId, cartData);

  const _getThisProductOnCart = (storeId, productId) => {
    const shopCartData = lodashFind(cartData, (d) => d.store_id === storeId);

    if (!lodashIsEmpty(shopCartData)) {
      const itemCartData = lodashFilter(
        shopCartData.items,
        (d) => d.item_id === productId
      );
      return itemCartData;
    }

    return [];
  };

  /**
   * Checks if an item can be rescheduled based on a daily / per time slot limit of the item and its category
   * @param {string} storeId - The store id for checking the cached store data
   * @param {string} itemId - The item id to be look up on store menu
   * @param {number} newQty - The new quantity or total quantity of item in cart or update in cart (optional)
   * @returns {boolean} Returns true if the item can be rescheduled if the stock is less than the limit, otherwise false.
   */
  const _isCanReschedule = (storeId, itemId, newQty) => {
    const isNewQtyUndefined = lodashIsUndefined(newQty);
    const isNewQtyNumber = typeof newQty === 'number';
    if (!isNewQtyUndefined && !isNewQtyNumber) {
      // if 3rd param or newQty is pass and its not a number
      return true; // just return true
    }
    const storeData = getSavedStore(
      storeId,
      storeId === shopRawData?.id ? shopRawData : undefined // if storeId is same on shopRawData?.id, pass it, else undefined
    );
    const existingItemOnCart = _getThisProductOnCart(storeId, itemId);
    const itemData = lodashFind(storeData?.menu, (ci) => ci.item.id === itemId);
    const categoryData = lodashFind(storeData?.categories, {
      category_name: itemData?.category,
    });
    const existingQuantity = existingItemOnCart?.reduce?.(
      (acc, obj) => acc + obj.quantity,
      0
    );
    // newQty is total cart item + planning to add e.g: existing item count is 3, quantity selector value is 2 so 3 + 2 = 5
    const newlyAddedQty = newQty - existingQuantity; // so we reduce the newQty to existingQuantity to determine the newlyAddedQty
    const categoryLimit =
      categoryData?.category_limit_per_day ||
      categoryData?.category_limit_per_time_slot?.[0]?.limit;
    const itemLimit =
      itemData?.item_limit_per_day ||
      itemData?.item_limit_per_time_slot?.[0]?.limit;
    const { isCategoryStock, remainingStock, stock } =
      MenuItemHelper.getItemStockConsideringCart(
        {
          id: itemId,
          stock: itemData?.stock,
          category_name: itemData?.category,
          category_stock: categoryData?.stock,
        },
        { id: storeId, menu_list: storeData?.menu },
        cartData
      );
    // Check if stock (item or category) is less than limit (per day or per time slot)
    const isStockLessThanLimit = isCategoryStock
      ? stock < categoryLimit
      : stock < itemLimit;
    const hasMoreStock =
      isNewQtyNumber && remainingStock !== 0 // if newQty params is pass and still has remainingStock (this usually pass on LimitReached component)
        ? remainingStock - newlyAddedQty !== 0 // get the difference of remainingStock and newlyAddedQty and check if still has stock after that
        : remainingStock !== 0; // check if still has stock
    return isStockLessThanLimit || hasMoreStock;
  };

  const _isPendingPayment = (storeId) => {
    const storeCart = _getStoreCart(storeId);
    return StoreHelper.isPaymentPending({
      status: storeCart.status,
      storeId: storeId,
      successfulOrder,
    });
  };

  const _addOrUpdateCart = async (config, withConsent) => {
    const {
      from,
      item,
      orderType,
      preOrderDate,
      silent,
      store,
      onCancel,
      onError,
      onSuccess,
    } = config;
    const isPaymentPending = _isPendingPayment(store.id);
    const limitMessage = {
      title: messages.CART_LIMIT.title,
      message: messages.CART_LIMIT.message(getCartLimit()),
    };
    if (!constants.isWeb && hasOrderBanner && !isOrderBannerMinimized) {
      // minimized the order banner, if mobile, has order banner and the order banner is not yet minimized
      dispatch(setOrderBannerMinimized(true));
    }
    if (isPaymentPending) {
      const error = {
        title: messages.PAYMENT_PENDING.title,
        message: messages.PAYMENT_PENDING.message(cartTerm),
      };
      if (!silent && !constants.isWeb) {
        toast.show(error, false);
      }
      return onError?.(error);
    } else if (isMealPlan) {
      // if meal plan, call the addMealPlan it should not proceed the code below
      onSuccess?.();
      return addMealPlan(item);
    }
    // if non meal plan item, proceed to this code
    const isItemUpdate = !!item?.cart_details_id;
    const itemInstructions = item.instructions || defaultInstructions;
    const sameVariantOnCart = CartHelper.getCartItem(
      store.id,
      item.id,
      item?.extras,
      itemInstructions,
      cartData
    );
    const thisItemOnCart = CartHelper.getCartItemByDetailsId(
      store.id,
      item?.cart_details_id,
      cartData
    );
    const isExtrasChanged = isItemUpdate
      ? item.cart_details_id === thisItemOnCart?.cart_details_id &&
        thisItemOnCart?.cart_details_id !== sameVariantOnCart?.cart_details_id
      : false;
    const shouldMergedToSameVariant = isExtrasChanged
      ? sameVariantOnCart?.cart_details_id ===
        CartHelper.getCartDetailsId(lodashOmit(item, ['cart_details_id']))
      : false;
    const getAdditionalPayload = (shouldMerge) => ({
      quantity: shouldMerge
        ? sameVariantOnCart.quantity + item.quantity
        : item.quantity || 1,
      cart_details_id: shouldMerge
        ? sameVariantOnCart.cart_details_id
        : item?.cart_details_id,
    });
    const isAPIupdate = isExtrasChanged && !shouldMergedToSameVariant;
    const isUIorAPIUpdate = isAPIupdate || !lodashIsEmpty(sameVariantOnCart);
    let isApiFailed = false;
    let isVisuallyUpdated = false;
    const payload = {
      store_id: store.id,
      item_id: item.id,
      instructions: itemInstructions,
      extras: item?.extras?.map((e) => e.id) || [],
      order_type: orderType,
      is_extras_changed: isExtrasChanged,
      ...getAdditionalPayload(shouldMergedToSameVariant),
    };
    // START - conditional payload
    if (!lodashIsEmpty(shopData?.order_for_later)) {
      // if user add to cart on store selected on order for later section on homepage
      payload.order_date = shopData.order_for_later.date;
      payload.order_time = shopData.order_for_later.time;
    } else if (!lodashIsEmpty(whenFilter) || !lodashIsEmpty(preOrderDate)) {
      // if has homepage when filter or checkout date coming from checkout page, add date and time to payload
      payload.order_date = preOrderDate?.date || whenFilter.date;
      payload.order_time = preOrderDate?.time || whenFilter.time;
    }
    // END - conditional payload
    // newlyAddedQuantity will determine how many qty is being added, its different on the payload.quantity because payload.quantity contains all quantity of the item in the cart
    const newlyAddedQuantity =
      payload.quantity - (sameVariantOnCart?.quantity || 0);
    const { error, itemInfo, storeInfo } = await getCartValidationInfo(
      payload.store_id,
      payload.item_id,
      shopRawData
    );
    if (error) {
      const error = {
        title: messages.COMMON_ERROR_TITLE,
        message: Service.handleErrorMessage(error?.message),
      };
      onError?.(error); // call onError if has callback
      if (silent) {
        return; // silent
      } else {
        return Alert.alert(error.title, error.message);
      }
    }

    const { isClosed, isClosing } = CheckoutHelper.isCanCheckout(
      storeInfo.store_hours,
      storeInfo.off_dates,
      storeInfo.pre_order_to_order_queue_timer,
      preOrderDate?.value || whenFilter?.value
    );
    if (isClosed || isClosing) {
      // show if store is closed or closing
      const isAcceptingAdvanceOrder = storeInfo.is_accepting_in_advanced_orders;
      onError?.(getPrompt(isAcceptingAdvanceOrder, isClosed)); // call onError if has callback
      if (!constants.isWeb) {
        showClosedOrClosingPrompt({
          backText: 'Ok',
          isAcceptingAdvanceOrder,
          isClosed,
          onReschedule: () => setRescheduleStoreId(payload.store_id),
        });
      }
      return;
    }

    const {
      categoryName,
      isCategoryStock,
      remainingStock = 0, // undefined should be 0 here
      stock,
    } = MenuItemHelper.getItemStockConsideringCart(
      itemInfo,
      storeInfo,
      cartData
    );
    if (
      CartHelper.isCartLimitExceeded(withConsent, payload.store_id, cartData)
    ) {
      if (silent) {
        return; // silent
      } else {
        if (constants.isWeb) {
          showModalPrompt(MODALPROMPT.prompt, {
            title: limitMessage.title,
            message: limitMessage.message,
            onCloseButtonClick: onCancel,
            buttons: [
              {
                size: 'small',
                status: 'danger',
                text: 'Confirm',
                onPress: () => _addOrUpdateCart(config, true),
              },
              {
                size: 'small',
                text: 'Cancel',
                onPress: onCancel,
              },
            ],
          });
        } else {
          Alert.alert(limitMessage.title, limitMessage.message, [
            {
              text: 'Confirm',
              onPress: () => _addOrUpdateCart(config, true),
            },
            {
              text: 'Cancel',
              onPress: onCancel,
            },
          ]);
        }
        return;
      }
    } else if (remainingStock !== -1 && newlyAddedQuantity > remainingStock) {
      // if newlyAddedQuantity is greater than remainingStock, undefined is acceptable here e.g: 5 > undefined is false
      const categoryLimit = `Sorry, but you can only add up to ${stock} items to the${
        categoryName ? ` ${categoryName}` : ''
      } category.`;
      /*
        NOTE on itemLimit: the reason we swap the quotation and dot is for formal letter because example
        item.name is `Spaghetti "Aglio e Olio"` (or single quote)
        the message should be:
        Sorry, blah blah blah of Spaghetti "Aglio e Olio." (formal, correct)
        Sorry, blah blah blah of Spaghetti "Aglio e Olio". (informal, incorrect)
      */
      const itemLastLetter = item.name?.slice(-1); // get item name last letter
      const isQuote = ['"', "'"].includes(itemLastLetter); // if last letter is single or double quote
      const formalItemName = isQuote
        ? `${item.name.replace(/.$/, '')}."`
        : `${item.name}.`; // if item name has quote make sure the last letter is .", if not last letter is .(dot)
      const stockPluralized = pluralize(stock, 'pc'); // pluralize the word pc to pcs of stock is more than 1
      const itemLimit = `Sorry, but you can only add up to ${stock} ${stockPluralized} of ${formalItemName}`; // do not add . here, . is in formalItemName
      const message = isCategoryStock ? categoryLimit : itemLimit;
      const error = { title: 'Maximum Limit Reached', message };
      const isCanReschedule = _isCanReschedule(
        store.id,
        item.id,
        item.quantity
      );
      onError?.({ ...error, isCanReschedule }); // call onError if has callback
      if (silent) {
        return; // silent
      } else {
        return Alert.alert(error.title, error.message, [
          ...(isCanReschedule
            ? [
                {
                  text: 'Reschedule',
                  onPress: () => setRescheduleStoreId(payload.store_id),
                },
              ]
            : []),
          { text: 'Ok' },
        ]);
      }
    }
    await dispatch(setAddingOrUpdatingCart()); // add loading flag
    const apiMethod = isAPIupdate ? userApi.updateCart : userApi.addToCart; // decide what api to use
    const reduxMethod = isUIorAPIUpdate ? updateCart : addToCart; // decide what redux to be dispatch
    // data to be save to redux
    const dataToBeSaveToRedux = {
      ...item,
      cart_details_id: CartHelper.getCartDetailsId(
        // if isExtrasChanged is true, removed the cart_details_id on item object since it will updated base on the updated extras
        isExtrasChanged ? lodashOmit(item, ['cart_details_id']) : item // if isExtrasChanged is false, just pass the item object since no need to update the cart_details_id
      ),
      extras: item.extras || [],
      instructions: payload.instructions,
      item_id: payload.item_id,
      prev_cart_details_id: payload.cart_details_id,
      quantity: payload.quantity,
      store_id: payload.store_id,
      store_name: store.name,
      to_be_synced: {
        newlyAddedQuantity,
        ...lodashPick(sameVariantOnCart, ['extras', 'instructions']),
      },
    };
    // fake api call delay, so user not abuse the add/update to cart button
    setTimeout(() => {
      if (!isApiFailed) {
        // if isApiFailed is false either this or api call gets resolved first
        isVisuallyUpdated = true; // flag if redux is updated on the UI for syncing purpose
        if (
          StoreHelper.isSuccessfulOrder({
            storeId: payload.store_id,
            successfulOrder,
          })
        ) {
          // if has successful order on this store, remove its flag because we just added item from that store so, its a new order
          dispatch(removeSuccessfulOrder(payload.store_id));
          /* remove the current cart data 
          // due to the polling request of ongoing orders
          // Reference: "previous order is pending and now the status gets updated to converted" on Orders/index.controller line 92
          */
          dispatch(removeCart(payload.store_id));
        }
        _clearContinueToPaymentData(payload.store_id);

        dispatch(reduxMethod(dataToBeSaveToRedux)); // dispatch redux
        if (shopData?.orderForLater) {
          // if store is from orderForLater, use the order_for_later date to auto fill the schedule selection on checkout
          dispatch(
            updateCheckoutDetails({
              store_id: store.id,
              keyToUpdate: checkout.keys.DELIVERY_SCHEDULE,
              keyValue: shopData.order_for_later,
            })
          );
        }
        onSuccess?.(dataToBeSaveToRedux); // call success callback if has any
      }
    }, fakeApiCallTimeout);
    // actual api call in background
    if (shouldMergedToSameVariant) {
      // when we merging this item to same variant on cart, we remove this item on cart
      const removePayload = { ...payload, ...getAdditionalPayload(false) };

      if (apiSync) await userApi.removeCart(removePayload);

      await dispatch(
        removeCartItem({
          store_id: removePayload.store_id,
          cart_details_id: removePayload.cart_details_id,
        })
      );
    }
    if (apiSync) {
      apiMethod(payload).then(({ ok, data }) => {
        if (ok) {
          AnalyticsHelper.addToCart(from, item); // save success to analytics if request is ok
        } else {
          isApiFailed = true; // needs to be first
          const actionMsg = isUIorAPIUpdate ? 'update' : 'add to';
          const msg =
            data?.message ||
            `Unable to ${actionMsg} ${cartTerm} at the moment.`;
          const transformedMsg = Service.handleErrorMessage(msg).replace(
            'this item',
            item.name
          );
          const error = { title: '', message: transformedMsg };
          AnalyticsHelper.error(msg); // save error to analytics if request is failed
          Sentry.reportError(
            `Error ${isUIorAPIUpdate ? 'updating' : 'adding'} cart`,
            data
          ); // report to sentry
          if (
            constants.STORE_CLOSING_OR_CLOSED_ERROR.includes(
              data?.message?.toLowerCase()
            )
          ) {
            // handle on api response if store is closing/closed to show the reschedule
            const isAcceptingAdvanceOrder =
              storeInfo.is_accepting_in_advanced_orders;
            const assumeClosed = true; // mark as already closed since we don't know if its closing or already closed
            onError?.(getPrompt(isAcceptingAdvanceOrder, assumeClosed)); // call onError if has callback
            if (!constants.isWeb) {
              showClosedOrClosingPrompt({
                backText: 'Ok',
                isAcceptingAdvanceOrder,
                isClosed: assumeClosed,
                onReschedule: () => setRescheduleStoreId(payload.store_id),
              });
            }
            return;
          } else {
            if (!silent && !constants.isWeb) {
              toast.show(error.message, false);
            }
            onError?.(error); // call onError if has callback
          }
        }

        // add delay just in case api finished faster than fake api call
        setTimeout(() => {
          dispatch(
            syncCart({
              data: dataToBeSaveToRedux,
              removeToBeSync: ok || !isVisuallyUpdated,
            })
          );
        }, fakeApiCallTimeout / 2); // reduce it by half
      });
    } else {
      AnalyticsHelper.addToCart(from, item);

      // add delay just in case api finished faster than fake api call
      setTimeout(() => {
        dispatch(
          syncCart({
            data: dataToBeSaveToRedux,
            removeToBeSync: true,
          })
        );
      }, fakeApiCallTimeout / 2); // reduce it by half
    }
  };

  const _removeThisProductOnCart = async ({
    item,
    storeId,
    onError,
    onSuccess,
  }) => {
    const isPaymentPending = _isPendingPayment(storeId);
    if (isPaymentPending) {
      const error = {
        title: messages.PAYMENT_PENDING.title,
        message: messages.PAYMENT_PENDING.message(cartTerm),
      };
      toast.show(
        constants.isWeb ? `${error.title}\n${error.message}` : error,
        false
      );
      return onError?.(error);
    }
    setRemoving(true);
    const storeData = lodashFind(cartData, { store_id: storeId });
    const payload = {
      cart_details_id: item.cart_details_id,
      store_id: storeId,
      item_id: item.item_id,
      quantity: 0,
      instructions: item.instructions,
      extras: item.extras,
      order_type: ORDER_TYPES.PICKUP,
    };

    const handleSuccessRemove = () => {
      const isCartNowEmpty = storeData.items.length === 1;
      onSuccess?.(isCartNowEmpty); // call onSuccess callback without waiting for the redux action
      _clearContinueToPaymentData(storeId);
      // also remove the cart item on redux
      dispatch(
        removeCartItem({
          store_id: payload.store_id,
          cart_details_id: payload.cart_details_id,
        })
      );
      if (isCartNowEmpty) {
        // if deleting last item on the store cart
        dispatch(removeCheckoutDetails(payload.store_id)); // remove the checkout data
      }
    };

    if (apiSync) {
      const { ok, data } = await userApi.removeCart(payload);

      if (ok) {
        handleSuccessRemove();
      } else {
        const error = {
          title: messages.COMMON_ERROR_TITLE,
          message: `Unable to remove item from your ${cartTerm} at the moment.`,
        };
        Sentry.reportError('Error removing cart item', { data, payload });
        if (constants.isWeb) {
          alert(error.message);
        } else {
          Alert.alert(error.title, error.message);
        }
        onError?.(error); // call onError callback
      }
    } else {
      handleSuccessRemove();
    }

    if (isMounted.current) {
      setRemoving(false);
    }
  };

  return {
    removing,
    cartData,
    addOrUpdateCart: _addOrUpdateCart,
    getStoreCart: _getStoreCart,
    getThisProductOnCart: _getThisProductOnCart,
    isCanReschedule: _isCanReschedule,
    removeThisProductOnCart: _removeThisProductOnCart,
  };
};
