import React from 'react';
import { View } from 'react-native';
import lodashIsEmpty from 'lodash/isEmpty';

import TagIconList from '../../Service/TagIconList';

import Badge from '../Badge';

type Props = {
  isUnavailable: boolean;
  stock: number;
  style?: any;
  tags: string[];
};
type TagIcon = {
  id: string;
  name: string;
  type: string;
  category: string;
  icon: string;
};
const ItemTag: React.FC = ({ isUnavailable, stock, style, tags }: Props) => {
  const tag = tags?.[0];
  const result = TagIconList.getTagIcon(tag) as TagIcon;
  const isNotAvailable = isUnavailable || stock === 0;
  if (!tag && !isNotAvailable) return null;
  if (isNotAvailable) {
    return (
      <View style={style}>
        {/* @ts-ignore: Badge is not typescript component yet */}
        <Badge
          type={Badge.TYPES.SOLID}
          category={Badge.CATEGORIES.DANGER}
          size="c2"
        >
          Unavailable
        </Badge>
      </View>
    );
  }
  return (
    <View style={style}>
      {/* @ts-ignore: Badge is not typescript component yet */}
      <Badge type={result.type} category={result.category} size="c2">
        {`${result.icon} ${result.name}`}
      </Badge>
    </View>
  );
};

export default ItemTag;
