import { Platform } from 'react-native';
import dayjs from 'dayjs';
import dayjsIsBetween from 'dayjs/plugin/isBetween';
import dayjsIsSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Clipboard from '@react-native-clipboard/clipboard';
import lodashFilter from 'lodash/filter';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashMapKeys from 'lodash/mapKeys';

import constants from '../Config/constants';

import ErrorTransformer from './ErrorTransformer';

const {
  currencySign,
  DATE_DISPLAY_FORMAT,
  DBDATE_FORMAT,
  minuteInterval,
  PREP_TIME_ALLOWANCE,
  TIME_ONLY_DISPLAY_CAPITAL_FORMAT,
} = constants;

dayjs.extend(dayjsIsBetween);
dayjs.extend(dayjsIsSameOrBefore);

// { firstName: 'somevalue', ... } will turn to { first_name: 'somevalue, ... }
function objectCamelKeysToSnake(obj) {
  const camelToSnakeCase = (str) =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  const newObject = {};

  Object.keys(obj).forEach((key) => {
    let keyValue = obj[key];
    newObject[camelToSnakeCase(key)] = keyValue;
  });

  return newObject;
}

// 9999 will transform to 9,999
function commafyNumber(num, withCurrencySign, fixed = 2) {
  if (typeof num !== 'number' || isNaN(num)) return num;
  // this function is to fixed the number to 2 decimal places without rounding up from 1.99 to 2
  // note: 1.4555 will not round to 1.46 also 1.999 will not round up to 2. last trailing number after 'fixed' will be removed only
  // reference: https://stackoverflow.com/a/11818658
  let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  let numStr = num.toString().match(re)[0];
  numStr = Number(numStr).toLocaleString();

  // if show as currency
  if (withCurrencySign) {
    // add floating point in the end, this only handle if no floating point at the end such as 1, 2, 99 etc.
    // but condition below should not get executed if the number has already floating point such as 1.19, 2.01, 99.99 etc
    // expect if floating point has only 1 digit we should add 0 at the end if decimal has only 1 digit e.g 1.9 to 1.90
    let [number, decimal] = numStr.split('.');
    if (!decimal) {
      numStr = `${number}.00`;
    } else if (decimal.length === 1) {
      numStr = `${number}.${decimal}0`;
    }

    return `${currencySign}${numStr}`;
  }

  return numStr;
}

function copyToClipboard(toCopy) {
  Clipboard.setString(toCopy);
}

function handleErrorMessage(errors) {
  const getPresentableErrorMessage = (error) =>
    ErrorTransformer[error] || 'An error has occurred.';
  if (Array.isArray(errors)) {
    errors = errors.filter((e) => e); // remove empty
    const initialText = errors.length > 1 ? '- ' : '';
    return `${initialText}${errors
      .map((error) => getPresentableErrorMessage(error))
      .join('\n- ')}`;
  } else if (typeof errors === 'object') {
    const objectToArray = Object.keys(errors).map((key) => errors[key]);
    const initialText = objectToArray.length > 1 ? '- ' : '';
    return `${initialText}${objectToArray
      .map((error) => getPresentableErrorMessage(error))
      .join('\n- ')}`;
  } else {
    return getPresentableErrorMessage(errors);
  }
}

// transform backend error to show on error below input field
function handleFormError(errors, showInAlert) {
  if (typeof errors === 'string') return handleErrorMessage(errors);
  const newErrorArray = errors.map((err) => {
    return Object.keys(err).map((key) => {
      return { [key]: handleErrorMessage(err[key]) };
    });
  });
  const newErrorShape = newErrorArray.reduce((obj, item) => {
    const arrToObj = item.reduce((o, i) => ({ ...o, ...i }), {});
    return { ...obj, ...arrToObj };
  }, {});
  if (showInAlert) {
    return handleErrorMessage(Object.keys(newErrorShape));
  }
  return newErrorShape;
}

function getStoreMapUrl(storeName, lat, lng) {
  const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
  const latLng = `${lat},${lng}`;
  const label = storeName;

  return Platform.select({
    ios: `${scheme}${label}@${latLng}`,
    android: `${scheme}${latLng}(${label})`,
    web: `https://www.google.com/maps/search/${label?.replace(
      /(\/)|(\s+)/g,
      '+'
    )}/@${latLng}z`,
  });
}

function generateDateOptions({
  storeHours,
  offDates,
  daysInAdvance,
  prepTime,
  dateAndTime,
  asap,
}) {
  const dateFormat = 'MM/DD/YYYY';
  const options = [];
  // initial variable need subtract 1 day, because on begining of the loop we add 1 day to it to prepare for next loop
  const now = dayjs(dateAndTime);
  const nowWithPreptime = now.add(prepTime + PREP_TIME_ALLOWANCE, 'minute');
  const initial = dayjs().startOf('day');
  const todayDate = initial.format(dateFormat);
  // loop through daysInAdvance to generate date options
  for (let i = 0; i <= daysInAdvance; i++) {
    const day = initial.add(i, 'days');
    const storeDate = day.format(dateFormat);
    if (offDates.includes(day.format(DBDATE_FORMAT))) {
      // if off dates in this loop, we add 1 on daysInAdvance for date skip
      daysInAdvance += 1;
    } else {
      // if off dates is not equal to day
      const isToday = todayDate === storeDate; // check if date is today
      const results = lodashFilter(storeHours, {
        day: day.day().toString(), // get current day (e.g: 0 - 6)
        is_open: true, // get only open time slot (ots)
      });
      // loop through time slots
      results.forEach(({ opening, closing }) => {
        const openDayjs = dayjs(`${now.format(DBDATE_FORMAT)} ${opening}`);
        let [startHour, startMinutes] = opening.split(':'); // initial startHour is opening hour
        let [endHour] = closing.split(':'); // initial endHour is closing hour

        // for checking for start hour, use only current time if today and store is open
        const isCurrentOpen = isToday && now.isSameOrAfter(openDayjs);
        if (isCurrentOpen) {
          // if today and current time is store open, use current time as start hour
          let currentHour = now.hour();
          let currentMinutes = now.minute();
          let minuteBeforeInterval = minuteInterval - currentMinutes;
          if (minuteBeforeInterval < 0) {
            currentHour += 1;
            currentMinutes = 0;
          } else {
            currentMinutes = 30;
          }
          startHour = currentHour;
          startMinutes = currentMinutes;
        }
        // for generating date and time options
        const endTime = dayjs(
          `${storeDate} ${closing}`, // date & time args
          `${dateFormat} ${constants.DBTIME_FORMAT}` // date & time format
        );
        let loopHr = Number(startHour);
        let loopMin = Number(startMinutes);
        while (loopHr <= endHour) {
          const currTS = dayjs(
            `${storeDate} ${loopHr}:${loopMin}`, // date & time args
            `${dateFormat} ${constants.DBTIME_FORMAT}` // date & time format
          );

          const fnName = isCurrentOpen ? 'isSameOrBefore' : 'isBefore';

          if (currTS[fnName]?.(endTime)) {
            // if not exceeding store closing hour, generate date and time object
            options.push({
              label: isToday
                ? `Today at ${currTS.format(TIME_ONLY_DISPLAY_CAPITAL_FORMAT)}`
                : currTS.format(DATE_DISPLAY_FORMAT), // display
              value: currTS.toISOString(), // reference value
              date: currTS.format(DBDATE_FORMAT), // this will be sent to checkout api
              time: currTS.format('HH:mm'), // this will be sent to checkout api
              isClosing: nowWithPreptime.isSameOrAfter(currTS), // check if closing with now + prep + window, it should not be after current time slot
              canAsap: isCurrentOpen,
            });
          }
          // after inserting the time to options, increment the loopHr and loopMin
          loopMin += minuteInterval;
          if (loopMin >= 60) {
            // if more than 60 the minutes then +1 to hours and reset minutes to 0
            loopHr += 1;
            loopMin = 0;
          }
        }
      });
    }
  }
  const [firstTs, secondTs] = options;
  const isCanAsapFirst = firstTs?.canAsap;
  const isCanAsapSecond = secondTs?.canAsap;
  const isClosingFirst = firstTs?.isClosing;
  if (
    ((isCanAsapFirst && !isClosingFirst) || isCanAsapSecond) && // if can asap first time slot AND not closing OR seconds timeslot is can asap
    !lodashIsEmpty(asap) // AND has asap parameter
  ) {
    return [asap, ...options.slice(1)]; // remove the first asap it's past the prep time and allowance
  } else if (isCanAsapFirst && isClosingFirst) {
    return options.slice(1); // if can asap but no asap params, remove the first asap it's past the prep time and allowance
  } else {
    return options; // if cannot asap and no asap params, return the dateOptions
  }
}

function toWebLatLng(data) {
  const newKeys = { latitude: 'lat', longitude: 'lng' };
  if (Array.isArray(data)) {
    return data.map((d) => {
      return lodashMapKeys(d, (value, key) => {
        return newKeys[key];
      });
    });
  } else {
    return lodashMapKeys(data, (value, key) => {
      return newKeys[key];
    });
  }
}

export default {
  objectCamelKeysToSnake,
  commafyNumber,
  copyToClipboard,
  handleErrorMessage,
  handleFormError,
  getStoreMapUrl,
  generateDateOptions,
  toWebLatLng,
};
