import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import LottieView from 'lottie-react-native';
import { Icon, Layout, Text } from '@ui-kitten/components';
import Collapsible from 'react-native-collapsible';
import dayjs from 'dayjs';
import lodashIsEmpty from 'lodash/isEmpty';

import lottieLiveJson from '../../Assets/lottie/live.json';

import Button from '../../Components/Button';
import Image from '../../Components/Image';
import OrderProgress from '../../Components/OrderProgress';
import StoreCartSummary from '../../Components/StoreCart/CartSummary';
import constants from '../../Config/constants';
import messages from '../../Config/messages';
import ImageHelper from '../../Helper/Image';
import OrderHelper, { STATUS } from '../../Helper/Order';
import Service from '../../Service';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import { ids, styles } from './styles';

const OrderAction = ({ isActive, text, onPress, ...rest }) => (
  <Button
    size="small"
    status="success"
    appearance={isActive ? 'outline' : 'filled'}
    onPress={onPress}
    {...rest}
  >
    {() => (
      <Text
        category="c1"
        appearance={isActive ? 'default' : 'alternative'}
        style={ThemeStyle.pageHorizontalSpacingSmall}
      >
        {text}
      </Text>
    )}
  </Button>
);

function OrderItemOnGoing({
  isActive,
  isArriving,
  showDetails,
  onShare,
  onIhaveArrivedPress,
  onStoreNamePress,
  onViewOrderPress,
  ...item
}) {
  const {
    additional_charges,
    charged_delivery_fee,
    curbside_pickup_details,
    delivery_paid_by_cash,
    delivery_tracking_link,
    items_total_amount,
    items,
    meal_plan,
    order_date,
    order_time,
    order_type,
    pickup_details,
    promo_discount,
    promo,
    status,
    store_details,
    total_amount,
    tracking_page,
    transaction_number,
  } = item;

  const isAsapText = order_time === 'asap_order_time';
  const displayDate = dayjs(
    `${order_date}${order_time ? ` ${order_time}` : ''}`
  ).format(constants.DATE_DISPLAY_FORMAT);
  const isPending = status === constants.ORDER_STATUS.PENDING;
  const isDelivery = order_type === constants.ORDER_TYPES.DELIVERY;
  const isCurbside = order_type === constants.ORDER_TYPES.CURBSIDE;
  const showTrackOrder = isDelivery && Boolean(delivery_tracking_link);
  const showIhaveArriveBtn = isCurbside && status === STATUS.ACCEPTED;
  const promotion = {};
  if (promo?.code) {
    // construct similar response shape of verify promo api since the shape is needed on summary
    promotion.description = promo.free_item_description;
    promotion.type = promo.type;
    promotion.promo = {
      applied: promo_discount,
      value: promo.amount_off || `${promo.percentage_off}%`,
    };
    promotion.delivery_discount = promo.subsidized_delivery_amount;
  }
  const image = ImageHelper.getOrderImage(store_details);
  const isDefaultPickupImage = ImageHelper.isPickupDefaultBanner(image);
  return (
    <Layout>
      <View
        dataSet={{ media: ids.itemOnGoingContainer }}
        style={[
          styles.itemOnGoingContainer,
          isActive && { backgroundColor: ThemeColor.lightGreen },
        ]}
      >
        <View style={styles.iconContainer}>
          <Icon
            name={showDetails ? 'angle-up' : 'angle-down'}
            pack="fa"
            fill={ThemeColor.green}
            style={styles.accordionIcon}
          />
        </View>

        <View style={[ThemeStyle.flex1, ThemeStyle.flexDirectionRow]}>
          <View
            style={[
              styles.imageStyle,
              isDefaultPickupImage && styles.noImageStyle,
            ]}
          >
            <Image
              source={{ uri: image }}
              style={{ width: 70, height: 70 }}
              resizeMode={isDefaultPickupImage ? 'contain' : 'cover'}
            />
          </View>
          <View style={[ThemeStyle.flex1, ThemeStyle.spacingLeft]}>
            <View
              style={[ThemeStyle.flexDirectionRow, ThemeStyle.spacingRight]}
            >
              <Button
                style={ThemeStyle.spacingBottomXSmall}
                onPress={onStoreNamePress}
                hitSlop={10}
                plain
              >
                <Text category="p2" numberOfLines={1}>
                  {store_details.name}
                </Text>
              </Button>
            </View>
            {isPending ? (
              <View style={styles.itemPendingContainer}>
                <View style={styles.itemVerfiyLottieMainContainer}>
                  <View style={styles.itemVerfiyLottieContainer}>
                    <LottieView
                      source={lottieLiveJson}
                      style={styles.itemOnGoingLottie}
                      webStyle={styles.itemOnGoingLottie}
                      colorFilters={[
                        {
                          keypath: 'Shape Layer 1',
                          color: ThemeColor.green,
                        },
                        {
                          keypath: 'Shape Layer 2',
                          color: ThemeColor.green,
                        },
                        {
                          keypath: 'Shape Layer 3',
                          color: ThemeColor.green,
                        },
                      ]}
                      autoPlay
                      loop
                    />
                  </View>
                  <Icon
                    name="clipboard-search-outline"
                    fill={ThemeColor.white}
                    pack="material"
                    style={styles.clipBoardIcon}
                  />
                </View>

                <View style={styles.itemVerifyContainer}>
                  <Text category="c2" appearance="alternative">
                    {`${messages.PAYMENT_PENDING.title}...`}
                  </Text>
                </View>
              </View>
            ) : (
              <Text category="p2" status={'basic'} style={ThemeStyle.bold}>
                {OrderHelper.getOrderStatusText(status, order_type)}
              </Text>
            )}

            <View
              style={[
                ThemeStyle.flexWrap,
                ThemeStyle.flex1,
                ThemeStyle.flexDirectionRowCenter,
                ThemeStyle.spacingTopXSmall,
              ]}
            >
              {!isPending && (
                <Text
                  category="p2"
                  style={[ThemeStyle.dimColor, ThemeStyle.spacingRightMedium]}
                >
                  Order Date: {isAsapText ? 'ASAP' : displayDate}
                </Text>
              )}
              {!isPending && transaction_number && (
                <Text
                  category="p2"
                  style={[ThemeStyle.dimColor, ThemeStyle.spacingRightMedium]}
                >
                  Order #{transaction_number}
                </Text>
              )}
              {!isPending && Boolean(tracking_page) && (
                <Button onPress={onShare} plain>
                  <View style={ThemeStyle.flexDirectionRowCenter}>
                    <Icon
                      name="share"
                      fill={ThemeColor.green}
                      style={{ width: 16, height: 16 }}
                    />
                    <View
                      dataSet={{ media: ids.itemShareText }}
                      style={styles.itemShareText}
                    >
                      <Text category="p2" style={ThemeStyle.dimColor}>
                        Share
                      </Text>
                    </View>
                  </View>
                </Button>
              )}
            </View>

            {!lodashIsEmpty(pickup_details) && (
              <Text category="p2" style={ThemeStyle.dimColor}>
                c/o: {pickup_details.first_name} {pickup_details.last_name}
              </Text>
            )}
          </View>
        </View>
        {(showTrackOrder || Boolean(order_type) || isCurbside) && (
          <View
            dataSet={{ media: ids.itemOnGoing2ndContainer }}
            style={styles.itemOnGoing2ndContainer}
          >
            {Boolean(order_type) && (
              <View
                dataSet={{ media: ids.itemOnGoingProgressContainer }}
                style={styles.itemOnGoingProgressContainer}
              >
                <OrderProgress value={status} orderType={order_type} />
              </View>
            )}
            {(showTrackOrder || isCurbside) && (
              <View
                style={[ThemeStyle.flexDirectionRowEnd, ThemeStyle.spacingTop]}
              >
                {showTrackOrder && (
                  <OrderAction
                    disabled={isPending}
                    isActive={isActive}
                    text="Track Order"
                    onPress={onViewOrderPress}
                  />
                )}
                {showIhaveArriveBtn && (
                  <OrderAction
                    disabled={curbside_pickup_details.has_arrived}
                    isActive={isActive && !isArriving}
                    loading={isArriving}
                    style={[
                      ThemeStyle.spacingLeftSmall,
                      curbside_pickup_details.has_arrived && {
                        backgroundColor: ThemeColor.gray,
                      },
                    ]}
                    text={
                      curbside_pickup_details.has_arrived
                        ? 'Coming up'
                        : 'I have arrived'
                    }
                    onPress={onIhaveArrivedPress}
                  />
                )}
              </View>
            )}
          </View>
        )}
      </View>

      <Collapsible collapsed={!showDetails} renderChildrenCollapsed={false}>
        <View style={ThemeStyle.pageHorizontalSpacing}>
          <Text category="p2" style={ThemeStyle.spacingBottomSmall}>
            Order Details
          </Text>
          {items.map((item, index) => (
            <View
              style={ThemeStyle.flexDirectionRowCenterSpaceBetween}
              key={index}
            >
              <Text
                category={constants.isWeb ? 'c1' : 'p2'}
                style={[ThemeStyle.flex1, ThemeStyle.spacingRightSmall]}
              >
                {item.name} &times; {item.quantity}
              </Text>
              {!meal_plan && (
                <Text category={constants.isWeb ? 'c1' : 'p2'}>
                  {Service.commafyNumber(
                    Number(item.price) * Number(item.quantity),
                    true
                  )}
                </Text>
              )}
            </View>
          ))}
          <StoreCartSummary
            additionalCharge={OrderHelper.getAdditionalChargesData(
              additional_charges
            )}
            isDelivery={order_type === constants.ORDER_TYPES.DELIVERY}
            cart={items}
            promotion={promotion}
            delivery={{
              fee: charged_delivery_fee
                ? Number(charged_delivery_fee)
                : undefined,
              mode: delivery_paid_by_cash ? 'cash' : 'non_cash',
            }}
            computed={{
              subTotal: items_total_amount,
              total: total_amount,
            }}
            noHspacing
          />
        </View>
      </Collapsible>
    </Layout>
  );
}

OrderItemOnGoing.propTypes = {
  onIhaveArrivedPress: PropTypes.func,
  onStoreNamePress: PropTypes.func.isRequired,
  onViewOrderPress: PropTypes.func.isRequired,
};

export default OrderItemOnGoing;
