import React from 'react';
import PropTypes from 'prop-types';
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { Button as UIKittenButton } from '@ui-kitten/components';
import lodashDebounce from 'lodash/debounce';

import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';
import constants from '../Config/constants';

// purpose of this is to have only 1 button importing from all over so if we need to change something on touchable opacity
// it can easily change here instead of every file importing touchable opacity example is changing the activeOpacity etc.
const activeOpacity = 0.7;
class Button extends React.Component {
  _onPress = (evt) => {
    const { onPress } = this.props;
    if (typeof onPress === 'function') {
      onPress(evt);
    }
  };

  _onClick = lodashDebounce(this._onPress, 300, {
    leading: true,
    trailing: false,
  });

  render() {
    const {
      children,
      hitSlop,
      isHoverable,
      lighterDisabledOpacity,
      loading,
      loadingColor,
      plain,
      style,
      onRef,
      ...rest
    } = this.props;

    const opacity = lighterDisabledOpacity ? 0.2 : activeOpacity;
    const disabledStyle = rest.disabled ? { opacity, elevation: 0 } : {};
    let hitSlopProps = undefined;
    if (typeof hitSlop === 'number') {
      hitSlopProps = {
        bottom: hitSlop,
        left: hitSlop,
        right: hitSlop,
        top: hitSlop,
      };
    } else if (typeof hitSlop === 'object') {
      hitSlopProps = hitSlop;
    }

    if (isHoverable) {
      return (
        <Pressable
          focusable={!constants.isWeb}
          ref={onRef}
          style={
            typeof style === 'function'
              ? (e) => [StyleSheet.flatten(style(e)), disabledStyle]
              : [style, disabledStyle]
          }
          onPress={this._onClick}
          hitSlop={hitSlopProps}
          {...rest}
        >
          {children}
        </Pressable>
      );
    }

    if (plain) {
      return (
        <TouchableOpacity
          ref={onRef}
          focusable={!constants.isWeb}
          style={[StyleSheet.flatten(style), disabledStyle]}
          activeOpacity={activeOpacity}
          hitSlop={hitSlopProps}
          {...rest}
          onPress={this._onClick}
        >
          {children}
        </TouchableOpacity>
      );
    }

    if (loading) {
      rest.disabled = true;
      rest.accessoryLeft = () => (
        <View style={[styles.indicator]}>
          <ActivityIndicator
            size={15}
            color={loadingColor || ThemeColor.white}
          />
        </View>
      );
    }

    return (
      <UIKittenButton
        ref={onRef}
        activeOpacity={activeOpacity}
        focusable={!constants.isWeb}
        style={[
          ThemeStyle.medium,
          ThemeStyle.fullRoundCorder,
          StyleSheet.flatten(style),
          disabledStyle,
        ]}
        hitSlop={hitSlopProps}
        {...rest}
        onPress={this._onClick}
      >
        {children}
      </UIKittenButton>
    );
  }
}

Button.defaultProps = {
  plain: false,
};

Button.propsTypes = {
  children: PropTypes.any.isRequired,
  isHoverable: PropTypes.bool, // usually for web
  lighterDisabledOpacity: PropTypes.bool,
  loading: PropTypes.bool,
  loadingColor: PropTypes.string,
  plain: PropTypes.bool,
  onRef: PropTypes.func,
};

const styles = StyleSheet.create({
  indicator: {
    marginRight: 10,
  },
});

export default Button;
