import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { useSelector } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';

import ItemMedium from '../../Components/Card/ItemMedium';
import constants from '../../Config/constants';
import useError from '../../Hooks/useError';
import { whenFilterSelector } from '../../RTK/filter/selectors';

import {
  shopFeaturedItemsSelector,
  shopSelector,
} from '../../RTK/shop/selectors';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import { ids, styles } from './stylesResponsive';
import useMealPlan from '../../Hooks/useMealPlan';

const FeaturedItems: React.FC = ({ onPress }: { onPress: Function }) => {
  const storeId = useSelector((state) => shopSelector(state)?.id);
  const isAcceptingAdvanceOrder = useSelector(
    (state) => shopSelector(state)?.is_accepting_in_advanced_orders
  );
  const featuredItems = useSelector(shopFeaturedItemsSelector);
  const whenFilter = useSelector(whenFilterSelector);
  const { ErrorCartOrCheckoutHandler } = useError(storeId);
  const { isMealPlan } = useMealPlan();

  if (lodashIsEmpty(featuredItems)) {
    return null;
  }

  const _renderItem = (item: any, index: number) => (
    <ItemMedium
      key={index}
      containerStyle={[ThemeStyle.spacingTop, ThemeStyle.spacingRight]}
      isMealPlan={isMealPlan}
      onError={(err: object) =>
        ErrorCartOrCheckoutHandler({ error: err, isAcceptingAdvanceOrder })
      }
      order_date={whenFilter?.date}
      onPress={() => onPress(item)}
      {...item}
    />
  );

  return (
    <View
      dataSet={{ media: ids.wrapper }}
      style={[
        styles.wrapper,
        ThemeStyle.pageBackground,
        ThemeStyle.pageVerticalSpacing,
        ThemeStyle.paddingLeft,
      ]}
    >
      <Text {...(constants.isWeb ? { category: 's1' } : SECTION_TITLE)}>
        Featured Items
      </Text>

      <View style={[ThemeStyle.flexWrap, ThemeStyle.flexDirectionRow]}>
        {featuredItems.map(_renderItem)}
      </View>
    </View>
  );
};

export default FeaturedItems;
