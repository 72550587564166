import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import constants from '../Config/constants';
import { changeWhenFilter } from '../RTK/filter';
import { toggleDatePicker } from '../RTK/utility';

function useHomeDatePicker() {
  const whenFilter = useSelector((state) => state.filter.home.when);
  const showDatePicker = useSelector((state) => state.utility.showDatePicker);
  const dispatch = useDispatch();

  /**
   * Toggle date picker on homepage.
   * @param {string | object} evt - Button event object or String for specific condition
   * @returns {Promise<void>} - A promise that resolves after dispatching the toggle action.
   */
  const _toggleDateTimePicker = (evt) => {
    dispatch(toggleDatePicker());
    if (typeof evt === 'string' && evt === 'cancel') {
      _onConfirm();
    }
  };

  const _onConfirm = (date) => {
    let updatedWhenFilter;
    if (date) {
      dispatch(toggleDatePicker());
      const dayjsObject = dayjs(date);
      updatedWhenFilter = {
        label: dayjsObject.format(constants.DATE_DISPLAY_FORMAT),
        value: dayjsObject.toISOString(),
        date: dayjsObject.format(constants.DBDATE_FORMAT),
        time: dayjsObject.format(constants.DBTIME_FORMAT),
      };
    }
    dispatch(changeWhenFilter(updatedWhenFilter));
  };

  return {
    showDatePicker,
    whenFilter,
    onConfirm: _onConfirm,
    toggleDateTimePicker: _toggleDateTimePicker,
  };
}

export default useHomeDatePicker;
